import { useMemo, useState } from "react";
import { Box, Stack, Typography, CardMedia } from "@mui/material";
import { CorrectIcon } from "../../assets/imgs/icons";
import { useBreakpointMd } from "../../utils/useStyleHooks";
import { ButtonInfo, ButtonPrimary } from "../../components/muiCustom/Button";
import { planList } from "../../components/templateData";
import { useGetStripePortal } from "../../redux/geoduckApi";

export default function MealList({
  planType,
  onChange
}:{
  planType?: string;
  onChange: (id: string, cb?: ()=> void )=>void
}){
  const isMd = useBreakpointMd();
  const [load, setLoad] = useState("");

  const getStripePortal = useGetStripePortal();
  const unsubscribeChange = ()=>{
    getStripePortal({
      onSuccess(res) {
        window.location.href = res.data.url;
      },
    });
  }

  const callbackLoad = ()=>{
    setLoad("");
  }

  const isDisabled = useMemo(()=>{
    let list = ["free", "starter_monthly", "professional_monthly"];
    let sec = planType || "free";
    let index = list.indexOf(sec);
    return list.slice(0, index+1);
  },[planType]);

  return (
    <Stack direction={ isMd ? "column" : "row" } flexWrap={ isMd ? "wrap" : "nowrap" } gap="0.2rem"
      width="100%">
      {
        planList.map((item, index)=>(
          <Box overflow="hidden" key={index}
            sx={{
              borderRadius:"0.16rem",
              border:"1px solid #35383F",
              minWidth: "3rem",
              width: "100%",
              bgcolor: "#1F222A",
              flex: "1"
            }}>
            <Stack alignItems="center" p="0.24rem 0.16rem" gap="0.08rem">
              <Typography lineHeight="0.38rem" fontSize="0.24rem" fontWeight={700}>
                {item.name}
              </Typography>
              <Stack direction="row" alignItems="flex-end" p="0.2rem 0 0">
                <Typography fontSize="0.4rem" lineHeight="0.4rem">
                  ${item.price}
                </Typography>
                <Typography fontSize="0.2rem" color="#E0E0E0" lineHeight="0.24rem">
                  / month
                </Typography>
              </Stack>
            </Stack>
            <Stack p="0.2rem" gap="0.16rem">
              {
                item.desc.map((data, suNum)=>(
                  <Stack key={suNum} direction="row" columnGap="0.12rem" alignItems="center" height="0.3rem">
                    <CorrectIcon sx={{ width: "0.24rem", height: "0.24rem" }} fill="#235DFF" />
                    <Typography fontSize="0.18rem" lineHeight="0.3rem" fontWeight="700" sx={{
                      textDecoration: suNum > item.isDis ? "line-through" : "none",
                      color: suNum > item.isDis ? "#797979" : "#FFF"
                    }}>
                      { data[0] }:
                    </Typography>
                    <Typography fontSize="0.16rem" lineHeight="0.3rem" sx={{
                      textDecoration: suNum > item.isDis ? "line-through" : "none",
                      color: suNum > item.isDis ? "#797979" : "#FFF"
                    }}>
                      { data[1] }
                    </Typography>
                  </Stack>
                ))
              }
            </Stack>
            <Box p="0.2rem">
              
              {
                planType !== "free" && planType === item.id ? (
                  <ButtonInfo sx={{ fontSize:"0.16rem", borderRadius:"0.4rem", width: "100%" }}
                    onClick={unsubscribeChange} >
                    Unsubscribe
                  </ButtonInfo>
                ) : (
                  <ButtonPrimary sx={{ fontSize:"0.16rem", width:"100%", borderRadius:"0.5rem" }}
                    disabled={ isDisabled.includes( item.id ) || load !== "" }
                    onClick={()=>{
                      setLoad(item.id);
                      onChange(item.id, callbackLoad);
                    }}>
                    { load === item.id ? (
                      <CardMedia
                        component="img" className="geoduck-rotates_cartoon"
                        sx={{width:"0.24rem", height:"0.24rem"}}
                        image="/image/animation/loading-animation.png"
                      />
                    ) : "TRY NOW!" }
                  </ButtonPrimary>
                )
              }
              
            </Box>
          </Box>
        ))
      }
      
    </Stack>
  )
}