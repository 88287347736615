import {
  Stack, styled, Box, Button,
  SelectChangeEvent, Select, MenuItem,
  IconButton,
  Typography
} from "@mui/material";
import {
  SearchIcon, CrossIcon, DisplayEyeIcon, HideEyeIcon, EmailIcon,
  ArrowSemicircleIcon
} from "../../assets/imgs/icons";
import { useEffect, useRef, useState } from "react";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { ButtonPrimary, ButtonText } from "./Button";
import { useIntl } from "react-intl";
import { Messages } from "../../localization/Messages";

const InputBox = styled("input")({
  width: "100%",
  color: "#FFFFFF",
  height: "0.6rem",
  fontSize: "0.18rem",
});

export function SearchInput({
  selectList,
  selectChange,
  placeholder,
  onChange,
}:{
  selectList?: string[],
  selectChange?: (value: string)=>any,
  placeholder:string,
  onChange:( value: string )=>any
}){
  const [value, setValue] = useState('');
  const [delBtn, setDelBtn] = useState(false);
  const stackRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(()=>{
    selectList && setValue(selectList[0]);
  },[]);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    selectChange && selectChange(event.target.value);
  };
  return (
    <Stack ref={stackRef} direction="row" alignItems="center" bgcolor="#1F222A"
      border="0.03rem solid transparent" borderRadius="0.12rem" width="100%">
      {
        selectList ? (
          <Select
            value={value}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              borderRight: "0.01rem solid #35383F", borderRadius: "0", mr: "0.1rem",
              ".MuiSelect-select": {
                padding: "0 0.2rem 0 0 !important", minWidth:"0.6rem", minHeight: "0 !important",
                textAlign: "center", fontSize:"0.18rem"
              },
              ".MuiSvgIcon-root": {
                color: "#FFFFFF", width: "0.2rem", height: "0.2rem", top: "calc(50% - 0.1rem)",
                right: "0.05rem" 
              },
            }}
          >
            {
              selectList.map((item,index) => (
                <MenuItem key={index} value={item}>{item}</MenuItem>
              ))
            }
          </Select>
        ) : (
          <Box fontSize="0" p="0 0.12rem 0 0.15rem">
            <SearchIcon sx={{width:"0.2rem", height:"0.2rem"}} />
          </Box>
        )
      }
      <Box flexGrow="1">
        <InputBox type="text" placeholder={ placeholder } className="input-transparent"
          ref={inputRef}
          onFocus={()=>{
            (stackRef.current as HTMLDivElement).style.boxShadow = "0 0 0.1rem #235DFF"
          }}
          onBlur={()=>{
            (stackRef.current as HTMLDivElement).style.boxShadow = "none"
          }}
          onInput={(e)=>{
            let tag = e.target as HTMLInputElement;
            setDelBtn(tag.value !== "");
            onChange( tag.value );
          }} />
      </Box>
      {
        delBtn && (
          <IconButton onClick={()=>{
            setDelBtn(false);
            onChange('');
            inputRef.current!.value = ""
            inputRef.current?.focus();
          }}>
            <ClearOutlinedIcon sx={{ width:"0.24rem", height: "0.24rem", color:"#FFF" }} />
          </IconButton>
        )
      }
    </Stack>
  );
};

export function AccountInput({
  title,
  defaultValue,
  onChange,
  placeholder,
  getCode,
  isDisabled,
  isPassword
}:{
  title: string;
  defaultValue?: string;
  onChange: (value: string)=> void;
  placeholder?: string;
  getCode?: ( callback: ()=>void )=> void;
  isDisabled?: boolean;
  isPassword?: boolean;
}){
  const intl = useIntl();
  const [lookState, setLookState] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const stackRef = useRef<HTMLDivElement>(null);
  const timer = useRef<NodeJS.Timeout | number>();
  const [codeText, setCodeText] = useState( intl.formatMessage(Messages.SendCode) );

  const resetCode = ()=>{
    let num = 59;
    setCodeText(intl.formatMessage(Messages.ResendIn) + " 59s");
    timer.current = setInterval(()=>{
      if(num <= 0){
        clearInterval(timer.current);
        setCodeText( intl.formatMessage(Messages.SendCode) )
      }else{
        num --;
        setCodeText( intl.formatMessage(Messages.ResendIn) + " " + num + "s")
      }
    },1000);
  };

  useEffect(()=>{
    if(defaultValue && inputRef.current && stackRef.current){
      inputRef.current.value = defaultValue;
      stackRef.current.style.borderBottomColor = "#235DFF";
    }
    return ()=>{
      clearInterval(timer.current);
    }
  },[])

  return (
    <Stack rowGap="0.14rem">
      <Typography fontSize="0.16rem" fontWeight={700} lineHeight="0.26rem">
        {title}
      </Typography>
      <Stack direction="row" columnGap="0.12rem" p="0 0 0.08rem 0" borderBottom="0.01rem solid #424242"
        ref={stackRef}>
        <input type={isPassword ? "password" : "text"} className="input-transparent"
          ref={inputRef}
          name={title}
          placeholder={placeholder}
          style={{flexGrow:"1", color:"#FFF",fontSize:"0.2rem", fontWeight:"700", height: "0.32rem"}}
          onInput={(e)=>{
            let tag = e.target as HTMLInputElement;
            onChange( tag.value );
            let color = tag.value ? "#235DFF" : "#424242"
            stackRef.current!.style.borderBottomColor = color;
          }}
        />
        {
          isPassword ? (
            <IconButton sx={{p:"0"}} onClick={()=>{
              inputRef.current!.type = lookState ? "password" : "text";
              setLookState(!lookState);
            }}>
              {
                lookState ? (
                  <DisplayEyeIcon sx={{ width:"0.28rem", height:"0.28rem" }} />
                ):(
                  <HideEyeIcon sx={{ width:"0.28rem", height:"0.28rem" }} />
                )
              }
            </IconButton>
          ) : getCode ? (
            <ButtonText sx={{fontSize:"0.16rem", color:"#235DFF", fontWeight:"700",p:"0"}}
              disabled={isDisabled || codeText !== intl.formatMessage(Messages.SendCode) } onClick={()=>{
                getCode( resetCode )
              }}>
              {codeText}
            </ButtonText>
          ) : title === intl.formatMessage(Messages.email) ? (
            <EmailIcon sx={{ width:"0.28rem", height:"0.28rem" }} />
          ) : <></>
        }
      </Stack>
    </Stack>
  )
};

export function PageInput({
  isSM,
  onChange,
  customText,
  customValue,
  readOnly
}:{
  isSM?: boolean;
  onChange: (value: string)=>void;
  customText?: string;
  customValue?: string;
  readOnly?: boolean;
}){
  const inputRef = useRef<HTMLInputElement>(null);
  const btnStyles = isSM ? "0.4rem" : "0.48rem";
  useEffect(()=>{
    if(inputRef.current && customValue){
      inputRef.current.value = customValue;
    }
  },[]);
  return (
    <Stack direction={ isSM ? "column" : "row"}
      bgcolor={ isSM ? "transparent" : "#FFF" }
      borderRadius="0.3rem" alignItems="center"
      p={ isSM ? "0" : "0.1rem 0.1rem 0.1rem 0.3rem" }
      gap="0.16rem" fontSize="0">
      <Box flexGrow="1" p={isSM ? "0.1rem" : "0" } bgcolor="#FFF" borderRadius="0.1rem"
        width="100%">
        <input type="text" className="input-transparent" ref={inputRef} readOnly={readOnly}
          style={{ width:"100%", fontSize: isSM ? "0.16rem" : "0.2rem", height: isSM ? "0.3rem" : "0.4rem" }}
          placeholder="Describe your story in words"
          onKeyDown={(e)=>{
            if(e.key === "Enter"){ onChange(inputRef.current!.value) }
          }}
        />
      </Box>
      <Button sx={{
        p:"0 0.2rem", height: btnStyles, borderRadius:"0.3rem",
        width: isSM ? "100%" : "auto",
        fontSize:"0.16rem",
        backgroundImage: "linear-gradient(90deg, #235DFF 0%, #BB2BFF 100%)",
        flexShrink:0
      }} onClick={()=>{
        onChange(inputRef.current!.value);
      }}>
        {
          customText || "Generate Video Now"
        }
        {/* <ArrowSemicircleIcon sx={{ width: iconStyles, height: iconStyles }} /> */}
      </Button>
    </Stack>
  )
}