import {
  Stack, Box, Typography, CardMedia,
  IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SearchHeaderIcon, ArrowLeftIcon } from '../../assets/imgs/icons';

export default function CurrencyHeader({
  isLogo,
  notBgcolor,
  isSearch,
  title,
}:{
  isLogo?: boolean;
  notBgcolor?: boolean;
  isSearch?: boolean;
  title: string;
}) {
  const navigate = useNavigate();
  return (
    <Stack direction="row" p="0.12rem 0.24rem" bgcolor={ notBgcolor ? "transparent" : "#181A20" } height="0.72rem"
      alignItems="center" justifyContent="space-between">
      {
        isLogo ? (
          <Box fontSize="0">
            <CardMedia component="img" src="/logo.png" sx={{ width: "0.28rem", height:"0.28rem" }} />
          </Box>
        ):(
          <IconButton sx={{p:"0"}} onClick={_=>navigate(-1)} >
            <ArrowLeftIcon sx={{ width: "0.28rem", height:"0.28rem" }} />
          </IconButton>
        )
      }
      <Typography fontSize="0.24rem" fontWeight="700">
        { title }
      </Typography>
      {
        isSearch ? (
          <IconButton>
            <SearchHeaderIcon sx={{ width: "0.28rem", height: "0.28rem" }} />
          </IconButton>
        ) : (
          <Box sx={{ width: "0.28rem", height: "0.28rem" }}></Box>
        )
      }
    </Stack>
  )
}