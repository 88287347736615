import { defineMessages } from 'react-intl';

/**
 * intl message item naming convension:
 *  - exactly match id with the default message
 *  - if the id is too long, concat the starting words in camel style to keep it unique
 *  - maximum id length is 20
 *  - keep all words without captialization
 */
export const Messages = defineMessages({
  HelloWorld: { id: 'HelloWorld', defaultMessage: 'Hello World' },
  signUp: { id: 'signUp', defaultMessage: 'Sign Up' },
  signIn: { id: 'signIn', defaultMessage: 'Sign In' },
  noPage404font: { id: 'noPage404font', defaultMessage: 'Oops! We are sorry the page was not found...' },
  noPage404desc: { id: 'noPage404desc', defaultMessage: 'The page you are looking for might have been removed, had its name changed or is temporarily unavailable.' },
  backToHomepage: { id: 'backToHomepage', defaultMessage: 'Back to home' },
  Language: { id: 'Language', defaultMessage: 'Language' },

  home: { id: 'home', defaultMessage: 'Home' },
  library: { id: 'library', defaultMessage: 'Library' },
  favorite: { id: 'favorite', defaultMessage: 'Favorite' },
  account: { id: 'account', defaultMessage: 'Account' },
  createNewVideo: { id: 'createNewVideo', defaultMessage: 'Create New Video' },
  startBlank: { id: 'startBlank', defaultMessage: 'Start Blank' },
  startedemptyCanvas: { id: 'startedemptyCanvas', defaultMessage: 'Get started with an empty canvas' },
  haveMaximumLimit: { id: 'haveMaximumLimit', defaultMessage: 'You have reached the maximum limit' },
  viewAll: { id: 'viewAll', defaultMessage: 'View All' },
  empty: { id: 'empty', defaultMessage: 'Empty' },
  copySuccess: { id: 'copySuccess', defaultMessage: 'Copy Success!' },
  OK: { id: 'OK', defaultMessage: 'OK' },
  generatingScript: { id: 'generatingScript', defaultMessage: 'Generating Script' },
  resetYourPassword: { id: 'resetYourPassword', defaultMessage: 'Reset your password' },
  resetPasswordTip: { id: 'resetPasswordTip', defaultMessage: 'Please enter your email and we will send an OTP code in the next step to reset your password.' },
  email: { id: 'email', defaultMessage: 'Email' },
  code: { id: 'code', defaultMessage: 'Code' },
  continue: { id: 'continue', defaultMessage: 'Continue' },
  emailFormatError: { id: 'emailFormatError', defaultMessage: 'Email format error' },
  VerificationCodeSent: { id: 'VerificationCodeSent', defaultMessage: 'Verification code has been sent!' },

  welcomeBack: { id: 'welcomeBack', defaultMessage: 'Welcome back' },
  PleaseEmailPassword: { id: 'PleaseEmailPassword', defaultMessage: 'Please enter your email & password to sign in.' },
  password: { id: 'password', defaultMessage: 'Password' },
  rememberMe: { id: 'rememberMe', defaultMessage: 'Remember me' },
  donTHaveAnAccount: { id: 'donTHaveAnAccount', defaultMessage: 'Don\'t have an account?' },

  createNewPassword: { id: 'createNewPassword', defaultMessage: 'Create New Password' },
  createNewPasswordTips: { id: 'createNewPasswordTips', defaultMessage: 'Create your new password. If you forget it, then you have to do forgot password.' },
  NewPassword: { id: 'NewPassword', defaultMessage: 'New Password' },
  ConfirmNewPassword: { id: 'ConfirmNewPassword', defaultMessage: 'Confirm New Password' },

  HelloThere: { id: 'HelloThere', defaultMessage: 'Hello there' },
  emailPasswordCreateAccountTip: { id: 'emailPasswordCreateAccountTip', defaultMessage: 'Please enter your email & password to create an account.' },
  name: { id: 'name', defaultMessage: 'Name' },
  agreeToStoryLoom: { id: 'agreeToStoryLoom', defaultMessage: 'I agree to the StoryLoom' },
  terms: { id: 'terms', defaultMessage: 'Terms' },
  TermsOfUse: { id: 'TermsOfUse', defaultMessage: 'Terms of Use' },
  PrivacyPolicy: { id: 'PrivacyPolicy', defaultMessage: 'Privacy Policy.' },
  AlreadyHaveAnAccount: { id: 'AlreadyHaveAnAccount', defaultMessage: 'Already have an account?' },
  next: { id: 'next', defaultMessage: 'Next' },

  SendCode: { id: 'SendCode', defaultMessage: 'Send Code' },
  ResendIn: { id: 'ResendIn', defaultMessage: 'Resend in' },

  Scene: { id: 'Scene', defaultMessage: 'Scene' },
  Duration: { id: 'Duration', defaultMessage: 'Duration' },
  Script: { id: 'Script', defaultMessage: 'Script' },
  Voice: { id: 'Voice', defaultMessage: 'Voice' },
  Music: { id: 'Music', defaultMessage: 'Music' },
  SorryYouAreFeature: { id: 'SorryYouAreFeature', defaultMessage: 'Sorry, you are unable to use this feature.' },
  GeneratingVideoTemplateTip: { id: 'GeneratingVideoTemplateTip', defaultMessage: 'Generating video template may take a few seconds. Please don\'t quit the app.' },
  AspectRatio: { id: 'AspectRatio', defaultMessage: 'Aspect Ratio' },
  Generate: { id: 'Generate', defaultMessage: 'Generate' },
  YourAIVideoIsReady: { id: 'YourAIVideoIsReady', defaultMessage: 'Your AI Video is Ready!' },
  YourAIVideoIsReadyDesc: { id: 'YourAIVideoIsReadyDesc', defaultMessage: 'Now you can make AI Videos easily and quickly. Check out our pro features to get all the benefits without any limitations.' },
  DownloadVideo: { id: 'DownloadVideo', defaultMessage: 'Download Video' },
  
  Download: { id: 'Download', defaultMessage: 'Download' },
  RemoveFromFavorite: { id: 'RemoveFromFavorite', defaultMessage: 'Remove from Favorite' },
  AddToFavorite: { id: 'AddToFavorite', defaultMessage: 'Add to Favorite' },
  Delete: { id: 'Delete', defaultMessage: 'Delete' },

  UpgradeToStarter: { id: 'UpgradeToStarter', defaultMessage: 'Upgrade to Starter!' },
  UpTo10Videos: { id: 'UpTo10Videos', defaultMessage: 'Up to 10 Videos' },
  DownloadVideoExport: { id: 'DownloadVideoExport', defaultMessage: 'Download Video, Export ...' },
  UpgradeToPro: { id: 'UpgradeToPro', defaultMessage: 'Upgrade to Pro!' },
  YouAreAtPro: { id: 'YouAreAtPro', defaultMessage: 'You are at Pro!' },
  UpTo30Videos: { id: 'UpTo30Videos', defaultMessage: 'Up to 30 Videos' },
  AddBRolls: { id: 'AddBRolls', defaultMessage: 'Add B-Rolls...' },
  ThereAreNoRestrictions: { id: 'ThereAreNoRestrictions', defaultMessage: 'There are no restrictions' },
  WelcomeBack: { id: 'WelcomeBack', defaultMessage: 'Welcome back' },
  Upgrade: { id: 'Upgrade', defaultMessage: 'Upgrade' },
  Recent: { id: 'Recent', defaultMessage: 'Recent' },

  Affiliate: { id: 'Affiliate', defaultMessage: 'Affiliate' },
  GetRewards: { id: 'GetRewards', defaultMessage: 'Get rewards' },
  ShareYourLink: { id: 'ShareYourLink', defaultMessage: 'Share your link' },
  ThereLimitEarnTip: { id: 'ThereLimitEarnTip', defaultMessage: 'There\'s no limit to how much you can earn.' },
  SingleInvitationCanEarn: { id: 'SingleInvitationCanEarn', defaultMessage: 'Single invitation can earn:' },
  Commision15: { id: 'Commision15', defaultMessage: '15% Commision!' },
  YourLink: { id: 'YourLink', defaultMessage: 'Your Link' },
  CopyLink: { id: 'CopyLink', defaultMessage: 'Copy Link' },
  WarningInvitationToFail: { id: 'WarningInvitationToFail', defaultMessage: 'Warning: Changing the link may cause the invitation to fail.' },
  History: { id: 'History', defaultMessage: 'History' },
  RegisteredUsers: { id: 'RegisteredUsers', defaultMessage: 'Registered Users' },
  TotalPayments: { id: 'TotalPayments', defaultMessage: 'Total Payments' },
  TotalReward: { id: 'TotalReward', defaultMessage: 'Total Reward' },
  Sort: { id: 'Sort', defaultMessage: 'Sort' },
  Plan: { id: 'Plan', defaultMessage: 'Plan' },
  Reward: { id: 'Reward', defaultMessage: 'Reward' },
  Time: { id: 'Time', defaultMessage: 'Time' },
  ThereIsNoRecordYet: { id: 'ThereIsNoRecordYet', defaultMessage: 'There is no record yet.' },

  InvalidCharacters: { id: 'InvalidCharacters', defaultMessage: 'Invalid characters' },
  PleaseFillInTheName: { id: 'PleaseFillInTheName', defaultMessage: 'Please fill in the name' },
  LogOut: { id: 'LogOut', defaultMessage: 'Log Out' },
  CurrentPlan: { id: 'CurrentPlan', defaultMessage: 'Current Plan' },
  MaximumQuantity: { id: 'MaximumQuantity', defaultMessage: 'Maximum Quantity' },
  CurrentQuantity: { id: 'CurrentQuantity', defaultMessage: 'Current Quantity' },
  AffiliateLink: { id: 'AffiliateLink', defaultMessage: 'Affiliate Link' },
  HelpCenter: { id: 'HelpCenter', defaultMessage: 'Help Center' },
  ChangePassword: { id: 'ChangePassword', defaultMessage: 'Change Password' },

  WelcomeTo: { id: 'WelcomeTo', defaultMessage: 'Welcome to' },
  successfullySubscribed: { id: 'successfullySubscribed', defaultMessage: 'You have successfully subscribed' },
  successfullySubscribedTip: { id: 'successfullySubscribedTip', defaultMessage: 'E-Receipt has been sent to your email address. Enjoy all the benefits!' },
  
  GeneratingVideo: { id: 'GeneratingVideo', defaultMessage: 'Generating Video' },
  GeneratingVideoTip: { id: 'GeneratingVideoTip', defaultMessage: 'Generating video may take a few seconds. Please don\'t quit the app.' },
  CAPTIONS: { id: 'CAPTIONS', defaultMessage: 'CAPTIONS' },
  BACKGROUNDMUSIC: { id: 'BACKGROUNDMUSIC', defaultMessage: 'BACKGROUND MUSIC' },
  ExportingVideo: { id: 'ExportingVideo', defaultMessage: 'Exporting Video' },
  ExportingVideoTip: { id: 'ExportingVideoTip', defaultMessage: 'Exporting the video may take a few minutes. After completion, we will inform you via email.' },

  NarrativeVolume: { id: 'NarrativeVolume', defaultMessage: 'Narrative Volume' },
  BGMEnabled: { id: 'BGMEnabled', defaultMessage: 'BGM Enabled' },
  NoBackgroundMusic: { id: 'NoBackgroundMusic', defaultMessage: 'No Background Music' },
  BGMVolume: { id: 'BGMVolume', defaultMessage: 'BGM Volume' },

  Export: { id: 'Export', defaultMessage: 'Export' },
  Save: { id: 'Save', defaultMessage: 'Save' },

  UnlockMoreContent: { id: 'UnlockMoreContent', defaultMessage: 'Unlock More Content!' },
  planLimitReached: { id: 'planLimitReached', defaultMessage: 'Your plan limit reached.' },
  createMoreVideos: { id: 'createMoreVideos', defaultMessage: 'Please upgrade to create more videos.' },
  checkBGMTip: { id: 'checkBGMTip', defaultMessage: 'Please select BGM after upgrading.' },
  downloadAfterUpgrading: { id: 'downloadAfterUpgrading', defaultMessage: 'Please download after upgrading.' },
  exportAfterUpgrading: { id: 'exportAfterUpgrading', defaultMessage: 'Please export after upgrading.' },

  forgotPassword: { id: 'forgotPassword', defaultMessage: 'Forgot Password?' },
  Share: { id: 'Share', defaultMessage: 'Share' },

  TryForFree: { id: 'TryForFree', defaultMessage: 'Try For Free' },
  ArtStyle: { id: 'Art Style', defaultMessage: 'Art Style' },
  
});
