import {
  Box,
  CardMedia,
  Link,
  Stack,
  Typography
} from "@mui/material";
import {
  ButtonText
} from "../muiCustom/Button";
import { UserNullAvatarIcon } from "../../assets/imgs/icons";
import { useIntl } from "react-intl";
import { Messages } from "../../localization/Messages";

function UserLogs({
  contentStyles
}:{
  contentStyles?: string;
}){
  const intl = useIntl();
  const color = "#000";
  const bgHoverColor = "rgba(105, 76, 230, 0.1)";
  return (
    <ButtonText sx={{
      "&:hover": { backgroundColor: bgHoverColor},
    }}>
      <UserNullAvatarIcon sx={{width:"1.25rem",height:"1.25rem"}} fill={ color } />
      <Typography fontSize="0.16rem" fontWeight={500}
        sx={{color: color, ml: "10px"}}
      >{intl.formatMessage(Messages.signUp)}</Typography>
    </ButtonText>
  )
}

type HeaderProps = {
  leftContent?: string;
  contentStyles?: string;
}

export default function Header({
  leftContent,
  contentStyles
}: HeaderProps){
  const intl = useIntl();
  return (
    <Stack direction="row" sx={{height:"5rem", padding:"20px 40px", width:"100%", m:"0 auto" }}
      component="header" maxWidth={'homepage'} >
      
      <Stack justifyContent="center">
        <Link href="/">
          <CardMedia component="img" src="/moolLogo.png" alt="logo"
            sx={{width:"6rem", filter: "drop-shadow(0 0.25rem 3px rgba(0, 0, 0, 0.2))"}} />
        </Link>
      </Stack>

      <Box sx={{ flexGrow: 1 }} />
      <UserLogs contentStyles={contentStyles} />
    </Stack>
  )
}