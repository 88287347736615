import { Stack, Box, CardMedia, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Messages } from "../../localization/Messages";
import MoreLists from "../../components/list/moreLists";
import CurrencyHeader from "../../components/header/currencyHeader";
import { useGetFavoriteVideo, useDeleteVideos } from "../../redux/geoduckApi";

export default function Favorite() {
  const intl = useIntl();
  const getVideos = useGetFavoriteVideo();
  const deleteVideos = useDeleteVideos();
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState<VideosAPIResponse[]>([]);
  const [load, setLoad] = useState(true);

  const getVideoList = () => {
    getVideos({
      body: {favorite: true},
      onSuccess: (res) => {
        let data = res.data || [];
        setVideoList(data);
      },
      onFinally() {
        setLoad(false);
      },
    })
  };

  const commentChange = (value: VideosAPIResponse) => {
    if(value.material.thumbnail){
      window.location.href = "/video-edit?id=" + value.id;
    }else{
      navigate("/create-video?id=" + value.id);
    }
  };

  const deleteData = (id: string, tag: HTMLElement | null, isFav?:boolean) => {
    if(tag){
      tag.style.opacity = "1";
      tag.classList.add("transition_opacity");
    };
    setTimeout(() => {
      let list = videoList.filter((item) => item.id !== id);
      setVideoList(list);
    }, 200);
    if(isFav) return;
    deleteVideos({
      body:{ video_id: id },
      onSuccess(res) {  },
      onError(err) {  }
    })
  };

  useEffect(()=>{
    getVideoList();
  },[]);

  return (
    <Stack width="100%" height="100%" >
      <CurrencyHeader title={intl.formatMessage(Messages.favorite)} isLogo={true} />
      <Box flexGrow="1" sx={{overflowY:"auto"}} p="0 0 0.8rem" >
        {
          videoList.length === 0 && !load && (
            <Stack alignItems="center" justifyContent="center" p="1.5rem 0" rowGap="0.4rem">
              <CardMedia src="/background/not_found.png" sx={{width:"2rem", height:"2rem"}} component="img" />
              <Typography fontSize="0.24rem" fontWeight="700">
              {intl.formatMessage(Messages.empty)}
              </Typography>
            </Stack>
          )
        }
        <MoreLists list={videoList} onChange={commentChange} onDelete={deleteData} />
      </Box>
      
    </Stack>
  )
}