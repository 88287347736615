import {
  Stack, Avatar, Box,
  IconButton,
  Typography,
  Button
} from "@mui/material";
import { useEffect, useMemo, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Messages } from "../../localization/Messages";

import { useGetVideos } from "../../redux/geoduckApi";
import { CrownIcon, NoticeIcon } from "../../assets/imgs/icons";
import { useGetUser } from "../../hooks/userHook";
import MediaList from "../../components/list/homeMediaList";

export default function Home() {
  const intl = useIntl();
  const navigate = useNavigate();
  const getVideos = useGetVideos();
  const user = useGetUser();
  const videoPrgs = useRef<string[]>([]);
  const [videoList, setVideoList] = useState<VideosAPIResponse[]>([]);
  const [load, setLoad] = useState(true);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const tipInfo = useMemo(()=>{
    let plan = user?.current_plan || "free";
    if(typeof plan !== "string" ) plan = "free";
    const planObj = {
      "free": {
        plan:"free",
        title: intl.formatMessage(Messages.UpgradeToStarter),
        tip1: intl.formatMessage(Messages.UpTo10Videos),
        tip2: intl.formatMessage(Messages.DownloadVideoExport)
      },
      "starter_monthly": {
        plan:"starter",
        title: intl.formatMessage(Messages.UpgradeToPro),
        tip1: intl.formatMessage(Messages.UpTo30Videos),
        tip2: intl.formatMessage(Messages.AddBRolls)
      },
      "professional_monthly": {
        plan:"pro",
        title: intl.formatMessage(Messages.YouAreAtPro),
        tip1: intl.formatMessage(Messages.UpTo30Videos),
        tip2: intl.formatMessage(Messages.ThereAreNoRestrictions)
      },
    }
    return planObj[plan];
  },[user]);

  const getVideoList = () => {
    getVideos({
      onSuccess: (res) => {
        if(!timer.current) return;

        let data = res.data || [];
        let prgs = data.slice(0, 3).map((item) => item.progress_bar);
        let difference = prgs.filter((item,index) => videoPrgs.current[index] !== item);
        if(difference.length > 0 || videoPrgs.current.length !== prgs.length){
          setVideoList(data);
        };
        videoPrgs.current = prgs;
      },
      onFinally() {
        setLoad(false);
      },
    })
  };

  const commentChange = (value: VideosAPIResponse) => {
    if(value.material.thumbnail){
      window.location.href = "/video-edit?id=" + value.id;
    }else{
      navigate("/create-video?id=" + value.id );
    }
  }

  useEffect(()=>{
    timer.current = setInterval(getVideoList, 15000);
    
    getVideoList();
    return () => {
      clearInterval(timer.current!);
      timer.current = null;
    }
  },[]);

  return (
    <Stack pb="0.8rem" width="100%" height="100%" sx={{overflowY:"auto"}}>
      <Stack direction="row" p="0.2rem 0.24rem 0.24rem" columnGap="0.16rem" alignItems="center">
        <Avatar src="" sx={{ width: "0.56rem", height: "0.56rem" }} />
        <Stack flexGrow="1">
          <Typography lineHeight="0.22rem" color="#E0E0E0">
            {intl.formatMessage(Messages.WelcomeBack)} 👋
          </Typography>
          <Typography lineHeight="0.30rem" mt="0.03rem" fontSize="0.18rem" fontWeight="700">
            { user?.name }
          </Typography>
        </Stack>
        {/* <IconButton sx={{ p:"0", border:"0.01rem solid #35383F", width:"0.52rem", height:"0.52rem" }}>
          <NoticeIcon sx={{ width: "0.28rem", height: "0.28rem" }} />
        </IconButton> */}
      </Stack>
      <Box p="0 0.24rem 0.24rem">
        <Stack direction="row" gap="0.16rem"
          sx={{
            width: "100%",
            backgroundImage: "url(/background/package_tip.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left center",
            borderRadius: "0.2rem",
            p:"0.24rem"
          }}>
          { tipInfo.plan === "pro" && <CrownIcon sx={{ width: "0.9rem", height: "0.9rem" }} /> }
          <Stack >
            <Typography lineHeight="0.38rem" fontSize="0.24rem" fontWeight={700}>
              {tipInfo.title}
            </Typography>
            <Typography fontSize="0.16rem" lineHeight="0.26rem" mt="0.04rem">
              {tipInfo.tip1}
              <br />
              {tipInfo.tip2}
            </Typography>
            { tipInfo.plan !== "pro" && (
              <Box mt="0.16rem">
                <Button sx={{
                  fontSize:"0.14rem", color:"#235DFF", bgcolor: "#FFF", borderRadius: "0.4rem",
                  p: "0.06rem 0.16rem", "&:hover": { bgcolor: "#FFF" }
                }} onClick={()=>{
                  navigate("/plan")
                }}>
                  {intl.formatMessage(Messages.Upgrade)}
                </Button>
              </Box>
            ) }
          </Stack>
        </Stack>
        
      </Box>
      <MediaList title={intl.formatMessage(Messages.Recent)} list={videoList.slice(0,5)}
        load={load} 
        allChange={()=>{
          navigate("/library")
        }} checkChange={commentChange} />
    </Stack>
  )
}
