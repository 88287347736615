import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import LanguageReducer from '../language/LanguageSlice';
import authReducer from './slice/AuthSlice';
import productReducer from './slice/productSlice';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';

import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  Language: LanguageReducer,
  auth: authReducer,
  product: productReducer,
});

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 1,
    storage: storage,
    // blacklist: ['product'],
  },
  rootReducer
);

const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      createStateSyncMiddleware({
        predicate: (action) => {
          return [
            'Auth/setLogin'
          ].includes(action.type);
        },
      }),
    ]),
});

initMessageListener(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export const persistor = persistStore(store);
export { store };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
