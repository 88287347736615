import { Button, SwipeableDrawer } from "@mui/material";
import { useRef, useEffect } from "react";
import { CheckIcon } from "../../assets/imgs/icons";
import { PageStack } from "../muiCustom/current";

export default function ModifyTopDrawer({
  swiperOpen,
  title,
  setSwiperOpen,
  preserveTitle
}:{
  swiperOpen: boolean;
  title: string;
  setSwiperOpen: (open: boolean) => void;
  preserveTitle: (value: string) => void;
}){
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if(inputRef.current && swiperOpen){
      inputRef.current.focus();
      inputRef.current.value = title;
    }
  }, [swiperOpen]);

  return (
    <SwipeableDrawer anchor="top" open={swiperOpen}
    onClose={_=> setSwiperOpen(false) }
    onOpen={_=> setSwiperOpen(true) }
    sx={{
      "& .MuiPaper-root": { bgcolor: "transparent", backgroundImage: "none"}
    }}>
      <PageStack sx={{ borderBottom: "0.01rem solid #35383F", overflow:"hidden", bgcolor: "#1F222A", p: "0.12rem 0.24rem"}}
        direction="row">
        <input type="text" className="input-primary" ref={inputRef}
          style={{padding:"0.04rem 0.1rem", color:"#FAFAFA", flexGrow: 1, fontSize:"0.2rem", fontWeight: "bold" }} />
        <Button
          onClick={()=>{
            preserveTitle(inputRef.current?.value || "" );
          }}
          sx={{
            minWidth: "0", bgcolor: "#235DFF", ml:"0.12rem",
            borderRadius:"0.06rem", "&:hover":{ bgcolor:"#235DFF" }
          }}>
          <CheckIcon sx={{color:"#FFFFFF", width:"0.24rem", height:"0.24rem"}} />
        </Button>
      </PageStack>
    </SwipeableDrawer>
  )
}