import { Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import { Messages } from "../../localization/Messages";
import { ButtonPrimary } from "../../components/muiCustom/Button";
import { CrownSkyBlueIcon } from "../../assets/imgs/icons";
import { Dialog } from "../../components/muiCustom/dialog";

export default function PlanDialog({
  planName,
  open,
  setOpen
}:{
  planName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}){
  const intl = useIntl();
  return (
    <Dialog open={open} onClose={()=>{ setOpen(false) }} >
      <Stack bgcolor="#1F222A" borderRadius="0.24rem" alignItems="center" p="0.4rem 0.32rem 0.32rem">
        <CrownSkyBlueIcon sx={{width:"1.86rem",height:"1.8rem"}} />
        <Typography fontSize="0.24rem" lineHeight="0.38rem" fontWeight="700" color="#235DFF" textAlign="center"
          sx={{width:"2.76rem", m:"0.32rem 0 0.16rem"}} >
          {intl.formatMessage(Messages.WelcomeTo)} {planName}!
        </Typography>
        <Typography fontWeight="400" fontSize="0.16rem" textAlign="center" lineHeight="0.26rem"
          sx={{width:"2.76rem" }} >
          {intl.formatMessage(Messages.successfullySubscribed)} {planName}! {intl.formatMessage(Messages.successfullySubscribedTip)}
        </Typography>
        <ButtonPrimary sx={{
          fontSize:"0.16rem", fontWeight:"700", width:"100%",borderRadius:"0.5rem",
          mt:"0.32rem", height:"0.5rem"
        }} onClick={()=>{
          setOpen(false)
        }}>
          OK
        </ButtonPrimary>
      </Stack>
    </Dialog>
  )
}