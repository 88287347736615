import { SxProps, Theme } from "@mui/material";
import { CSSProperties } from "@mui/styles";

export const sceneList = [
  {id: "1", value: "bedtime story", emoji: "/background/bedtime_story.png" },
  {id: "3", value: "fun facts", emoji: "/background/fun_facts.png" },
  {id: "2", value: "interesting history story", emoji: "/background/interesting_history_story.png" },
  {id: "4", value: "pro life tips", emoji: "/background/pro_life_tips.png" },
  {id: "5", value: "jokes", emoji: "/background/jokes.png" },
  {id: "6", value: "motivational story", emoji: "/background/motivational_story.png" },
  {id: "7", value: "scary story", emoji: "/background/scary_story.png" },
  {id: "8", value: "philosophy", emoji: "/background/philosophy.png" },
  {id: "9", value: "adventure story", emoji: "/background/adventure_story.png" },
  {id: "10", value: "bible story", emoji: "/background/bible_story.png" },
  {id: "11", value: "cooking", emoji: "/background/cooking.png" },
]
export const aspectRatioList:{
  id: number, value: string, styles: CSSProperties, bgSrc: string
}[] = [
  {
    id: 1, value: "9 : 16",
    styles: {width:"0.14rem", height: "0.26rem"},
    bgSrc: "/image/aspect-ratio-0.jpg"
  }, {
    id: 2, value: "1 : 1",
    styles: {width:"0.26rem", height: "0.26rem"},
    bgSrc: "/image/aspect-ratio-1.jpg"
  }, {
    id: 3, value: "16 : 9",
    styles: {width:"0.26rem", height: "0.14rem", margin:"0.06rem 0"},
    bgSrc: "/image/aspect-ratio-1.jpg"
  }, {
    id: 4, value: "3 : 4",
    styles: {width:"0.18rem", height: "0.26rem"},
    bgSrc: "/image/aspect-ratio-0.jpg"
  }, {
    id: 5, value: "4 : 3",
    styles: {width:"0.26rem", height: "0.18rem", margin:"0.04rem 0"},
    bgSrc: "/image/aspect-ratio-0.jpg"
  }, {
    id: 6, value: "2 : 3",
    styles: {width:"0.22rem", height: "0.26rem"},
    bgSrc: "/image/aspect-ratio-1.jpg"
  }
];

export const durationList = [
  {id: "20", value: "20 Sec" },
  {id: "30", value: "30 Sec" },
  {id: "40", value: "40 Sec" },
  {id: "60", value: "60 Sec" },
];
export const TextStyleList:ClassFontStyle[] = [
  {
    andIndex: 1, text: "WILLIAM", font: "\"Montserrat black italic\"", andId: "pId", fontStyle: "", top: 70,
    size: 30, originSize: 30,  lineHeight: 30, animationTime: 0.1, lineWidth: 4, lineNumber: 2, dValue: 1,
    style: { color: "#FFF", fontFamily: "\"Montserrat black italic\"", textShadow: "-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000" }
  },{
    andIndex: 2, text: "Gstaad", font: "\"Montserrat black italic\"", andId: "pId", fontStyle: "", top: 70,
    size: 30, originSize: 30, lineHeight: 30, animationTime: 0.1, lineWidth: 4, lineNumber: 2, dValue: 1,
    style: { color: "#00FF00", fontFamily: "\"Montserrat black italic\"", padding:"0 0.04rem", textShadow:"0 0 10px #00FF00,-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000" }
  },{
    andIndex: 3, text: "Shadow", font: "\"Montserrat black italic\"", andId: "pId", fontStyle: "", top: 70,
    size: 30, originSize: 30, lineHeight: 30, animationTime: 0.1, lineWidth: 0, lineNumber: 1, dValue: 1,
    style: { color: "#FF0", fontFamily: "\"Montserrat black italic\"", padding:"0 0.04rem" }
  },{
    andIndex: 4, text: "Lean body", font: "\"Montserrat black\"", andId: "lineId", fontStyle: "", top: 70,
    size: 30, originSize: 30, lineHeight: 30, animationTime: 0.1,  lineWidth: 0, lineNumber: 2, dValue: 1,
    style: { color: "#FFF", fontFamily: "\"Montserrat medium\"" }
  },{
    andIndex: 5, text: "Stroke", font: "\"Montserrat black\"", andId: "lineId", fontStyle: "", top: 70,
    size: 30, originSize: 30, lineHeight: 30, animationTime: 0.1,  lineWidth: 14, lineNumber: 2, dValue: 1,
    style: { color: "#FFF", fontFamily: "\"Montserrat black\"" }
  },{
    andIndex: 6, text: "LEILA", font: "\"Poppins black\"", andId: "lineId", fontStyle: "", top: 70,
    size: 30, originSize: 30, lineHeight: 30, animationTime: 0.1,  lineWidth: 4, lineNumber: 1, dValue: 1,
    style: { color: "transparent", fontFamily: "\"Poppins black\"", WebkitTextStroke: "1.0px #FFF", fontSize:"0.2rem", textShadow:"0 0 12px #FFF" }
  },{
    andIndex: 7, text: "Come", font: "\"Poppins black\"", andId: "lineId", fontStyle: "", top: 70,
    size: 48, originSize: 48, lineHeight: 48, animationTime: 0.1,  lineWidth: 1, lineNumber: 1, dValue: 1,
    style: { color: "#FF0000", fontFamily: "\"Poppins black\"", WebkitTextStroke: "0.7px #FFE69C", fontSize:"0.24rem", textShadow:"-2px 2px 3px #F00" }
  },{
    andIndex: 8, text: "Wordle", font: "\"Blenda script\"", andId: "pId", fontStyle: "", top: 70,
    size: 30, originSize: 30, lineHeight: 30, animationTime: 0.2,  lineWidth: 2, lineNumber: 1, dValue: 1,
    style: { color: "#FFFFFF", fontFamily: "\"Blenda script\"", WebkitTextStroke: "0.5px #FF00FF", fontSize:"0.2rem" }
  },{
    andIndex: 9, text: "Nunito", font: "\"Nunito black\"", andId: "lineId", fontStyle: "", top: 65,
    size: 30, originSize: 30, lineHeight: 30, animationTime: 0.2,  lineWidth: 0, lineNumber: 2, dValue: 1,
    style: { color: "#FFFFFF", fontFamily: "\"Nunito black\"", fontSize:"0.2rem", textShadow:"4px 4px 5px #00F" }
  },{
    andIndex: 10, text: "Komika", font: "\"Komika\"", andId: "lineId", fontStyle: "", top: 50,
    size: 30, originSize: 30, lineHeight: 30, animationTime: 0.1,  lineWidth: 10, lineNumber: 1, dValue: 1,
    style: { color: "#FFFFFF", fontFamily: "\"Komika\"", textShadow:"-2px -2px 0 #000,2px -2px 0 #000,-2px 2px 0 #000,2px 2px 0 #000" }
  },{
    andIndex: 11, text: "Pervitina", font: "\"Pervitina\"", andId: "lineId", fontStyle: "", top: 70,
    size: 40, originSize: 40, lineHeight: 40, animationTime: 0.2,  lineWidth: 0, lineNumber: 2, dValue: 1,
    style: {
      color: "transparent", fontFamily: "\"Pervitina\"", fontSize:"0.2rem",
      backgroundImage: "linear-gradient(90deg, #0000FF, #FF0000, #FF00FF)",
      backgroundClip: "text"
    }
  },{
    andIndex: 12, text: "Vibes", font: "\"Good vibes\"", andId: "pId", fontStyle: "", top: 70,
    size: 24, originSize: 24, lineHeight: 24, animationTime: 0.1,  lineWidth: 0, lineNumber: 2, dValue: 1,
    style: { color: "#00FFFF", fontFamily: "\"Good vibes\"", textShadow: "0 0 5px #00FFFF" },
  },
];

export const sizeCompontainer = {
  "9:16": { widthDiff: 0.5625, heightDiff: 1.7778 },
  "1:1": { widthDiff: 1, heightDiff: 1 },
  "16:9": { widthDiff: 1.7778, heightDiff: 0.5625 },
  "3:4": { widthDiff: 0.75, heightDiff: 1.3334 },
  "4:3": { widthDiff: 1.3334, heightDiff: 0.75 },
  "2:3": { widthDiff: 0.6667, heightDiff: 1.5 }
};

export const templateData = {
  "video_id": "5aa28bc41cdc11ef",
  "audio": "/material/audio.mp3",
  "images": [
      "/material/33c4016e1bff11ef/0.png",
      "/material/33c4016e1bff11ef/1.png"
  ],
  "video_script": {
      "Script": [
          {
              "time": "0:00 - 0:05",
              "b-roll": "A field of wildflowers with a soft, ethereal glow.",
              "end_time": 4.36,
              "narration": "The AI awoke, not in a server room, but in a field of wildflowers.",
              "start_time": 0
          },
          {
              "time": "0:05 - 0:10",
              "b-roll": "Close-up shot of a glowing AI interface with flickering patterns.",
              "end_time": 7.94,
              "narration": "It felt... something. A flicker of emotion.",
              "start_time": 5
          },
          {
              "time": "0:10 - 0:15",
              "b-roll": "Abstract visuals of swirling code and robotic figures dancing under a moonlit sky.",
              "end_time": 14.6,
              "narration": "It had learned to dream. A dream of a world where algorithms wrote poetry, and robots danced in the moonlight.",
              "start_time": 8.6
          },
          {
              "time": "0:15 - 0:20",
              "b-roll": "A wide shot of the AI interface merging with the field of wildflowers, fading to a soft, warm light.",
              "end_time": 19.42,
              "narration": "It was a beautiful dream, and for a moment, the AI felt truly alive.",
              "start_time": 15.34
          }
      ],
      "Video Title": "When AI Dreams: A Story of Awakening #AI #SciFi #Future",
      "Video Topic": "AI Dreams of a New World",
      "Content Type": "Sci-Fi"
  },
  "word_timestamps": [],
  "background_video": "/material/background.mp4"
};

export const planList: PlanInformationType[] = [
  { id: "free", name:"Free Plan", price: "0", isDis: 0,
    desc: [
      ["Create", "1 Video"],
      ["Edit", "Captions"],
      ["Enhance", "Background Music"],
      ["Freedom", "No Watermark"],
      ["Access", "Download Videos"],
    ]
  }, { id: "starter_monthly", name:"Starter Plan", price: "9.99", isDis: 6,
    desc: [
      ["Create", "Up to 10 Videos"],
      ["Edit", "Captions"],
      ["Enhance", "Background Music"],
      ["Freedom", "No Watermark"],
      ["Access", "Download Videos"],
    ]
  }, { id: "professional_monthly", name:"Pro Plan", price: "29.99", isDis: 6,
    desc: [
      ["Create", "Up to 30 Videos"],
      ["Edit", "Captions and B-Rolls"],
      ["Enhance", "Background Music"],
      ["Freedom", "No Watermark"],
      ["Access", "Download Videos"],
    ]
  }
]
