import { Box, Stack } from '@mui/material';
import { ReactElement } from 'react';
import { useGlobalStyles } from '../assets/styles/style';
import Header from '../components/header';
import { useBreakpointXs } from '../utils/useStyleHooks';
import PullToRefresh from 'react-simple-pull-to-refresh';

type PageLayoutWrapperProps = {
  showHeader?: boolean;
  children: ReactElement;
  showMobileViewPadding?: boolean;
  fullWidth?: boolean;
  customWidth?: string;
  borderBottom?: boolean;
  isPullable?: boolean;
};
export default function PageLayoutWrapper({
  children,
  showHeader,
  isPullable = false,
}: PageLayoutWrapperProps) {
  const gs = useGlobalStyles();
  const handleRefresh = async () => {
    window.location.reload();
  };
  const component = (
    <>
      <Box width="100%" height="100%">
        {showHeader && ( <Header /> )}
        {children}
      </Box>
    </>
  );
  return isPullable ? (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <PullToRefresh onRefresh={handleRefresh} isPullable={true}>
      {component}
    </PullToRefresh>
  ) : (
    component
  );
}

export function HomePageLayoutWrapper({
  children,
  showHeader,
  showMobileViewPadding = true,
  fullWidth = false,
  borderBottom = true,
  customWidth,
}: PageLayoutWrapperProps) {
  const gs = useGlobalStyles();
  const isMobileView = useBreakpointXs();
  return (
    <Stack alignItems={'center'}>
      <Stack
        className={borderBottom ? gs.borderBottom : ''}
        alignItems={'center'}
        width="100%"
      >
        {showHeader && (
          <Box maxWidth={'1440px'} width="100%" px="8px">
            <Header />
          </Box>
        )}
      </Stack>
      <Box
        maxWidth={ customWidth ? customWidth : fullWidth ? '100%' : '1440px'}
        width="100%"
        sx={{
          padding: isMobileView
            ? `${showMobileViewPadding ? 16 : 0}px !important`
            : '0px',
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}
