import { Stack, Box, Avatar, Typography, Button, IconButton } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import PasswordIcon from '@mui/icons-material/Password';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import { Messages } from "../../localization/Messages";
import { logout, setUserInfo, updateUserKey } from "../../redux/slice/AuthSlice";
import {
  ArrowRightPathIcon, ScriptIcon, LockIcon,
  AboutIcon, LogoutIcon, EditIcon,
  CrownIcon
} from "../../assets/imgs/icons";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useGetUser } from "../../hooks/userHook";
import { useBreakpointSm } from "../../utils/useStyleHooks";
import {
  usePostLogout,
  usePostResetUserinfo,
  usePostAffiliateAccessRecord
} from "../../redux/geoduckApi";
import { useGetRefreshToken } from "../../hooks/userHook";
import { ButtonInfo, ButtonPrimary, ButtonText } from "../../components/muiCustom/Button";
import ModifyTopDrawer from "../../components/muiCustom/drawer";
import { showMessage } from "../../components/muiCustom/messageTip";
import { planList } from "../../components/templateData";
import PlanDialog from "./planDialog";
import { useLanguage } from "../../hooks/language";

type ParamChangeType = "terms" | "privacy" | "lang" | "pass" | "logout" | "link";

export default function Myprofile(){
  const intl = useIntl();
  const language = useLanguage();
  const languageName = useMemo(()=>{
    let lang = language || "en";
    let lansObj = {
      en: "English",
      es: "Español"
    };
    return lansObj[lang as "en" | "es"];
  },[language]);
  
  const user = useGetUser();
  const isSm = useBreakpointSm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refreshToken = useGetRefreshToken();
  const postLogout = usePostLogout();
  const postResetUserinfo = usePostResetUserinfo();
  const postAffiliateAccessRecord = usePostAffiliateAccessRecord();

  const [planDialogOpen, setPlanDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const planInfo = useMemo(()=>{
    if(!user) return planList[0];
    if(!user.stripe_customer) return planList[0];
    if(!user.stripe_customer.plan) return planList[0];

    let strId = user.stripe_customer.plan;

    return planList.find(item => item.id === strId) as PlanInformationType;
  },[user]);

  
  const submitInvitation = ()=>{
    let localCode = localStorage.getItem("invitation:code");
    if( !localCode ) return;
    postAffiliateAccessRecord({
      notTip: true,
      body: { affiliate_code: localCode, action: "paid" }
    })
  }

  const isOpenTips = ()=>{
    let pathData = new URLSearchParams(window.location.search);
    let success = pathData.get("success");
    let canceled = pathData.get("canceled");
    if(success === "true"){
      setPlanDialogOpen(true);
      submitInvitation();
      window.history.replaceState(null, "", window.location.pathname);
    }
    if(canceled === "true"){
      window.history.replaceState(null, "", window.location.pathname);
    }
  }

  useEffect(()=>{
    isOpenTips();
  },[]);

  const preserveTitle = (value: string) => {
    let valueTitle = value.trim();
    let regex = /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]+$/;
    if(!regex.test(valueTitle)){
      return showMessage.error(intl.formatMessage(Messages.InvalidCharacters));
    };
    if(!valueTitle || valueTitle === ""){
      return showMessage.error(intl.formatMessage(Messages.PleaseFillInTheName));
    };
    if(valueTitle && valueTitle !== ""){
      dispatch( updateUserKey({ name: valueTitle }) );
      postResetUserinfo({ body: { username: valueTitle } });
    }
    setDrawerOpen(false);
  };

  const logoutChange = ()=>{
    if(!refreshToken) return;
    postLogout({
      body: { refresh_token: refreshToken },
    });
    dispatch( logout() );
  };

  const paramChange = (id: ParamChangeType)=>{
    const obj: { [key: string]: ()=> void } = {
      "terms": ()=>{ navigate("/terms_of_use") },
      "privacy": ()=>{ navigate("/privacy_policy") },
      "lang": ()=>{ navigate("/lang") },
      "pass": ()=>{ navigate("/account/change-password") },
      "logout": logoutChange,
      "link": ()=>{ navigate("/invitation") },
    };
    obj[id]();
  }

  const BtnItem = useCallback(({
    id,
    name,
    content,
    icon,
  }:{
    id: ParamChangeType,
    name: string,
    content?: string,
    icon: React.ReactNode
  }) => (
    <Button sx={{
      p: "0.12rem 0.24rem",
      gap: "0.2rem",
      cursor:"pointer", alignItems:"center",
      "&:hover": {
        bgcolor: "#1F222A"
      }
    }} onClick={()=>{ paramChange(id) }}>
      {icon}
      <Typography lineHeight="0.3rem" fontWeight="700" fontSize="0.18rem"
        sx={{
          color: id === "logout" ? "#F75555" : "#FFFFFF"
        }}>{name}</Typography>
      <Box flexGrow="1"></Box>
      { content && (
        <Typography fontSize="0.18rem" lineHeight="0.3rem" fontWeight="700">
          {content}
        </Typography>
      ) }
      <ArrowRightPathIcon sx={{ width: "0.24rem", height: "0.24rem" }} />
    </Button>
  ), []);

  const DivisionLine = useCallback(({
    title
  }:{
    title: string
  })=>(
    <Stack direction="row" gap="0.1rem" alignItems="center">
      <Typography fontWeight="600" lineHeight="0.22rem" color="#9E9E9E">
        {title}
      </Typography>
      <Box flexGrow="1" height="0.01rem" bgcolor="#35383F"></Box>
    </Stack>
  ),[]);

  const PlanItems = useCallback(({ title, cont }:{[key:string]: string})=>{
    return (
      <Stack direction="row" gap="0.1rem">
        <Typography fontSize="0.16rem" fontWeight="700" lineHeight="0.3rem">
          {title}:
        </Typography>
        <Typography fontSize="0.16rem" lineHeight="0.3rem" fontWeight={400}>
          {cont}
        </Typography>
      </Stack>
    )
  },[]);

  return (
    <Stack width="100%" height="100%" pb="0.8rem" sx={{overflowY:"auto"}}>
      <PlanDialog open={planDialogOpen} setOpen={setPlanDialogOpen} planName={planInfo.name} />
      <Stack p="0.2rem 0 0" >
        <Stack direction="row" columnGap="0.2rem" alignItems="center" p="0 0.24rem">
          <Avatar src="" sx={{ width: "0.56rem", height: "0.56rem", flexShrink:0 }} />
          <Stack flex="1" height="100%" overflow="hidden">
            <Box width="100%" overflow="hidden">
              <Button sx={{
                p:"0", gap:"0.08rem", maxWidth:"100%",
                "&:hover": { color: "#235DFF" }
              }} onClick={()=>{
                setDrawerOpen(true);
              }}>
                <Typography lineHeight="0.24rem" fontSize="0.18rem" fontWeight="700" color="inherit"
                  className="ellipsis">
                  { user?.name }
                </Typography>
                <EditIcon sx={{ width: "0.24rem", height: "0.24rem" }} fill="currentColor" />
              </Button>
            </Box>
            <Box flexGrow="1"></Box>
            <Typography lineHeight="0.18rem">
              { user?.email }
            </Typography>
          </Stack>
          <IconButton onClick={logoutChange} sx={{flexShrink:0, borderRadius:"0.2rem"}}>
            {
              !isSm && (
                <Typography color="#F75555" mr="0.1rem" fontSize="0.16rem" lineHeight="0.24rem">
                  {intl.formatMessage(Messages.LogOut)}
                </Typography>
              )
            }
            <LogoutIcon sx={{ width: "0.24rem", height: "0.24rem" }} fill="#F75555" />
          </IconButton>
        </Stack>

        <Box p="0.24rem"><DivisionLine title={intl.formatMessage(Messages.CurrentPlan)} /></Box>
        <Stack direction={isSm ? "column" : "row"} gap="0.2rem" flex="1"
          onClick={()=>{ navigate("/plan") }}
          sx={{
            borderRadius:"0.12rem", m: "0 0.24rem", p: "0.16rem",
            bgcolor: planInfo.id !== "professional_monthly" ? "#1F222A" : "#235DFF",
            cursor: "pointer", transition: "all 0.2s",
            "&:hover": {
              bgcolor: planInfo.id !== "professional_monthly" ? "#131313" : "#4F7DFF",
            }
          }}>
          <Stack flexGrow="1">
            <PlanItems title={intl.formatMessage(Messages.Plan)} cont={planInfo.name} />
            <PlanItems title={intl.formatMessage(Messages.MaximumQuantity)} cont={ planInfo.desc[0][1] } />
            <PlanItems title={intl.formatMessage(Messages.CurrentQuantity)} cont={ user?.video_count.toString() || "0" } />
          </Stack>
          
          { planInfo.id !== "professional_monthly" ? (
            <Stack direction="row" gap="0.1rem" alignItems="center">
              <ButtonPrimary sx={{ fontSize:"0.16rem", borderRadius:"0.4rem", p:"0.06rem 0.2rem" }} >
                {intl.formatMessage(Messages.Upgrade)}
              </ButtonPrimary>
            </Stack>
          ) : (
            <Stack gap="0.1rem" alignItems="center">
              <CrownIcon sx={{ width: "0.9rem", height: "0.9rem" }} />
            </Stack>
          ) }
          
          
        </Stack>
      </Stack>
      <Stack p="0 0 0.24rem">
        <Box p="0.24rem 0.24rem 0.12rem"><DivisionLine title="About" /></Box>
        <BtnItem id="link" name={intl.formatMessage(Messages.AffiliateLink)} icon={<AttachFileIcon sx={{ width: "0.24rem", height: "0.24rem", color:"#FFF" }} />} />
        <BtnItem id="terms" name={intl.formatMessage(Messages.TermsOfUse)} icon={<ScriptIcon sx={{ width: "0.24rem", height: "0.24rem" }} />} />
        <BtnItem id="privacy" name={intl.formatMessage(Messages.PrivacyPolicy)} icon={<LockIcon sx={{ width: "0.24rem", height: "0.24rem" }} />} />
        <BtnItem id="lang"
          name={intl.formatMessage(Messages.Language)}
          content={languageName}
          icon={<AboutIcon sx={{ width: "0.24rem", height: "0.24rem" }} />} />
        <BtnItem id="pass" name={intl.formatMessage(Messages.ChangePassword)} icon={<PasswordIcon sx={{ width: "0.24rem", height: "0.24rem", color:"#FFF" }} />} />
      </Stack>
      <ModifyTopDrawer
        swiperOpen={drawerOpen}
        title={user?.name || ""}
        setSwiperOpen={setDrawerOpen}
        preserveTitle={preserveTitle}
      />
      
    </Stack>
  )
}