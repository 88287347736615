
import { useState, memo, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  IconButton,
  Button,
  Stack,
  Typography,
  Box,
  SwipeableDrawer
} from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useBreakpointSm } from "../../utils/useStyleHooks";
import {
  HomePageIcon,
  HomePageCheckIcon,
  LibraryIcon,
  LibraryCheckIcon,
  FavoriteIcon,
  FavoriteCheckIcon,
  AccountIcon,
  AccountCheckIcon,
  AddIcon
} from "../../assets/imgs/icons";
import { useGetMaxmuiNumber } from "../../hooks/userHook";
import { showMessage } from "../muiCustom/messageTip";
import { useIntl } from "react-intl";
import { Messages } from "../../localization/Messages";
import { PageBox } from "../muiCustom/current";
import { PlanUpgradeDialog } from "../muiCustom/dialog";

function GeoduckFooder(){
  const [swiperOpen, setSwiperOpen] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const isCreate = useGetMaxmuiNumber();
  const navigate = useNavigate();
  const location = useLocation();
  const sm = useBreakpointSm();
  const intl = useIntl();

  const openSwiperChange = ()=>{
    if(!isCreate) return setOpenPlan(true);
    navigate("/create-video");
    // setSwiperOpen(true);
  }

  const iconStyle = useMemo(() => ({ width: "0.24rem", height: "0.24rem" }), []);
  const list = useMemo(()=>[
    { label: intl.formatMessage(Messages.home), value: "/", icon: <HomePageIcon sx={iconStyle} />, checkIcon: <HomePageCheckIcon sx={iconStyle} /> },
    { label: intl.formatMessage(Messages.library), value: "/library", icon: <LibraryIcon sx={iconStyle} />, checkIcon: <LibraryCheckIcon sx={iconStyle} /> },
    { label: intl.formatMessage(Messages.favorite), value: "/favorite", icon: <FavoriteIcon sx={iconStyle} />, checkIcon: <FavoriteCheckIcon sx={iconStyle} /> },
    { label: intl.formatMessage(Messages.account), value: "/myprofile", icon: <AccountIcon sx={iconStyle} />, checkIcon: <AccountCheckIcon sx={iconStyle} /> }
  ],[]);

  const BtnNavigation = useCallback(({
    data
  }:{
    data: { label: string, value: string, icon: JSX.Element, checkIcon: JSX.Element }
  }) => {
    return (
      <Button sx={{
        flexDirection:"column",
        color: location.pathname === data.value ? "#FFF" : "#9E9E9E",
        flex: sm ? "1" : "0",
        height: sm ? "auto" : "0.64rem",
        width: sm ? "auto" : "0.64rem",
        bgcolor: sm ? "transparent" : "#35383F",
        borderRadius:" 0.4rem",
        p: "0",
        "&:hover": { bgcolor: "transparent" }
      }} onClick={_=>navigate(data.value)}>
        {location.pathname === data.value ? data.checkIcon : data.icon }
        <Typography fontSize="0.1rem" color="inherit" variant="caption">{data.label}</Typography>
      </Button>
    )
  }, [location, sm]);

  return (
    <Stack direction="row" alignItems="center" justifyContent={ sm ? "space-around" : "center" }
      gap={ sm ? "0" : "0.16rem"}
      sx={{
        width:"100%", background: "#181A20D9", p:"0.08rem 0", backdropFilter: "blur(0.08rem)",
        position:"absolute", bottom:0, left:0, zIndex: 1000
      }}>
      {
        list.slice(0, 2).map((item, index) => (
          <BtnNavigation data={item} key={item.value} />
        ))
      }
      <Stack justifyContent="center" alignItems="center" flex={ sm ? 1 : 0 }>
        <IconButton onClick={openSwiperChange}
        sx={{
          bgcolor:"#235DFF",
          width: sm ? "0.44rem" : "0.64rem",
          height: sm ? "0.44rem" : "0.64rem",
          transform: "scale(1)",
          transition: "all 0.3s",
          "&:hover": { bgcolor: "#235DFF" },
          "&:active": { transform: "scale(0.8)" }
        }}><AddRoundedIcon color="primary" sx={{...iconStyle, fontSize:"0.14rem"}} /></IconButton>
      </Stack>
      {
        list.slice(2, 4).map((item, index) => (
          <BtnNavigation data={item} key={item.value} />
        ))
      }
      <SwipeableDrawer anchor="bottom" open={swiperOpen}
        disableSwipeToOpen={ !isCreate }
        onClose={_=> setSwiperOpen(false) }
        onOpen={_=> setSwiperOpen(true) }
        sx={{
          "& .MuiPaper-root": { bgcolor: "transparent", backgroundImage: "none"}
        }}>
        <PageBox
          sx={{
            borderRadius: "0.28rem 0.28rem 0 0", border: "0.01rem solid #35383F",
            overflow:"hidden", bgcolor: "#1F222A", p: "0 0.24rem"
          }}>
          <Box p="0.08rem 0"> <Box m="0 auto" width="0.38rem" height="0.03rem" bgcolor="#35383F"></Box> </Box>
          <Box p="0.16rem 0 0.24rem" borderBottom="1px solid #35383F">
            <Typography fontWeight="700" fontSize="0.24rem" textAlign="center">
              {intl.formatMessage(Messages.createNewVideo)}
            </Typography>
          </Box>
          <Stack p="0.24rem 0" gap="0.16rem">
            <Button
              onClick={()=>{
                setSwiperOpen(false);
                navigate("/create-video");
              }}
              sx={{
                bgcolor: "#35383F", p: "0.16rem", borderRadius: "0.12rem", flexDirection: "column",
                "&:hover": { bgcolor: "#35383F" }
              }}>
              <Stack p="0.06rem" >
                <AddIcon sx={{width:"0.24rem", height:"0.24rem"}} />
              </Stack>
              <Typography fontSize="0.18rem" lineHeight="0.29rem" fontWeight="bold"
                mt="0.12rem" color="#FFF">{intl.formatMessage(Messages.startBlank)}</Typography>
              <Typography textAlign="center" fontSize="0.14rem" lineHeight="0.22rem" fontWeight="400">
                {intl.formatMessage(Messages.startedemptyCanvas)}
              </Typography>
            </Button>
          </Stack>
        </PageBox>
      </SwipeableDrawer>
      <PlanUpgradeDialog
        open={openPlan} setOpen={setOpenPlan}
        content={intl.formatMessage(Messages.createMoreVideos)}
      />
    </Stack>
  )
};

export default memo(GeoduckFooder);
