import {
  Button as MuiButton,
  ButtonProps,
  SxProps,
  styled
} from "@mui/material"

// export type ButtonProps = {
//   sx?: SxProps;
//   children: React.ReactNode;
//   onClick?: () => void;
// }

export const ButtonText = styled(({...props}: ButtonProps)=>(
  <MuiButton {...props} variant="text" />
))({
  borderRadius:"0.1rem",
  "&:disabled":{
    backgroundColor: "transparent",
    color: "#888"
  }
});

export const ButtonPrimary = styled(({...props}: ButtonProps)=>(
  <MuiButton {...props} />
))({
  backgroundColor: "#235DFF",
  "&:hover": { backgroundColor: "#235DFF" },
  "&:disabled": {
    backgroundColor: "#131313", color: "#888",
  }
});

export const PromptButton = styled(MuiButton)(({ theme }) => ({
  color: "#131313", borderRadius: "5rem", fontSize: "14px", fontWeight: 400, minWidth: "20px",
  "&:hover": { backgroundColor: "rgba(105, 76, 230, 0.1)" }
}));

export const ButtonInfo = styled(({...props}: ButtonProps)=>(
  <MuiButton {...props} />
))({
  color: "#FFFFFF", backgroundColor: "#35383F", borderRadius: "0.5rem",
  "&:hover": { backgroundColor: "#35383F" },
  "&:disabled": {
    backgroundColor: "#131313", color: "#888",
  }
});

export const ButtonDanger = styled(({...props}: ButtonProps)=>(
  <MuiButton {...props} />
))({
  color: "#FFFFFF", backgroundColor: "#F75555", borderRadius: "0.5rem",
  "&:hover": { backgroundColor: "#F75555" },
  "&:disabled": {
    backgroundColor: "#131313", color: "#888",
  }
});
