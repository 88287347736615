import { Stack, Box } from "@mui/material";

export function PageSwiper(){
  const list = [
    "/media/TheMagicalBirthdayCandleATaleofFamilyFun.mp4",
    "/media/MagicalForestBedtimeStory.mp4",
    "/media/TheMiracleofJesusFeeding5000AStoryofFaithandCompas.mp4",
    "/media/HeartwarmingAdventureNionioNuonuosSearchforEvelyn.mp4",
    "/media/TheMagicalBirthdayCandleATaleofFamilyFun.mp4",
    "/media/TheUnbelievableEscapefromAlcatraz.mp4",
    "/media/TheEnchantedNightABedtimeStorytoDriftYoutoSleepBedtimeStorySleepTales.mp4",
    "/media/TheMiracleofJesusFeeding5000AStoryofFaithandCompas.mp4",
    "/media/MagicalForestBedtimeStory.mp4",
    "/media/TheUnbelievableEscapefromAlcatraz.mp4"
  ]
  return (
    <Stack direction="row" columnGap="0.4rem" className="geoduck-audio_one_cartoon" 
      sx={{
        mt:"0.5rem", mb:"0.2rem"
      }}>
      {
        list.map((item, index)=>(
          <Box key={index} sx={{
            width:"2rem", borderRadius:"0.1rem", overflow:"hidden", flexShrink: 0, textAlign:"center",
            transition:"transform 0.3s", fontSize:"0", border: "0.04rem solid #5B53FF",
            "&:hover":{
              transform: "scale(1.1)", 
            }
          }}>
            <video width="100%" playsInline autoPlay loop muted>
              <source src={item} type="video/mp4" />
            </video>
          </Box>
        ))
      }
    </Stack>
  )
}