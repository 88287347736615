import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { useTheme } from './assets/styles/theme';
import './assets/styles/main.css';
import './assets/styles/animation.css';
import { IntlProvider } from 'react-intl';
import i18n from './localization/i18n';
import { useLanguage } from './hooks/language';
import MainRouter from './route/MainRouter';
import { MessageTip } from './components/muiCustom/messageTip';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const lang = useLanguage();
  const theme = useTheme();
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <IntlProvider
        messages={i18n.getMessages(lang)}
        key={lang}
        locale={lang}
        defaultLocale={i18n.getDefaultLocale()}
      >
        {/*  */}
        <GoogleOAuthProvider clientId="535170830358-f6pa9u07fasqc90t5q09a2sleuhh28tk.apps.googleusercontent.com">
          <MessageTip />
          <MainRouter />
          <div style={{ position:"fixed", top:"-888px", left:"0", fontFamily:"'Roboto Black'" }}>h</div>
          <div style={{ position:"fixed", top:"-800px", left:"0", fontFamily:"'Lora semibold italic'" }}>c</div>
        </GoogleOAuthProvider>
      </IntlProvider>
    </ThemeProvider>
  );
}

function Root() {
  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor} loading={<></>}> {/*  */}
          <App />
        </PersistGate>
      </ReduxProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));