import { CircularProgress } from '@mui/material';
import React,{ Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { RootState } from '../redux/store';

import HomePage from '../pages/Homepage';
import HomePageMain from '../pages/HomePageMain';

import PageLayoutWrapper from '../pages/PageLayoutWrapper'; // is header ?
import ProtectedRoute from './ProtectedRoute'; // is login ?

const Page404 = React.lazy(() => import('../pages/404'));
const CreateVideo = React.lazy(() => import('../pages/createVideo'));
const ProductEdit = React.lazy(() => import('../pages/productEdit'));
const GenerateVideo = React.lazy(() => import('../pages/generateVideo'));
const Account = React.lazy(() => import('../pages/account'));
const MealIntroduce = React.lazy(() => import('../pages/meal/mealIntroduce'));
const Invitation = React.lazy(() => import('../pages/pageRouter/invitation'));
const Lang = React.lazy(() => import('../pages/pageRouter/lang'));
const TermsOfUse = React.lazy(() => import('../pages/pageRouter/termsOfUse'));
const PrivacyPolicy = React.lazy(() => import('../pages/pageRouter/privacyPolicy'));
const Preview = React.lazy(() => import('../pages/sharePreview/preview'));

export default function MainRouter() {
  const auth = useSelector((state: RootState) => state.auth);
  
  let pathData = new URLSearchParams(window.location.search);
  let code = pathData.get("code");
  if(code){
    localStorage.setItem("invitation:code", code);
  }
  
  return (
    <Router>
      <Routes>
        <Route path="/create-video"
          element={
            <Suspense fallback={<></>}>
              <ProtectedRoute element={
                <PageLayoutWrapper>
                  <CreateVideo />
                </PageLayoutWrapper>
              } />
            </Suspense>
          }
        />
        <Route path="/video-edit" 
          element={
            <Suspense fallback={<></>}>
              <ProtectedRoute element={
                <PageLayoutWrapper>
                  <ProductEdit />
                </PageLayoutWrapper>
              } />
            </Suspense>
          }
        />
        <Route path="/invitation"
          element={
            <Suspense fallback={<></>}>
              <ProtectedRoute element={
                <PageLayoutWrapper>
                  <Invitation />
                </PageLayoutWrapper>
              } />
            </Suspense>
          }
        />
        <Route path="/generate/:id"
          element={
            <Suspense fallback={<></>}>
              <PageLayoutWrapper children={<GenerateVideo />} />
            </Suspense>
          }
        />
        
        <Route path="/account/*"
          element={
            <Suspense fallback={<CircularProgress />}>
              <PageLayoutWrapper children={<Account />} />
            </Suspense>
          }
        />
        <Route path="/plan"
          element={
            <Suspense fallback={<CircularProgress />}>
              <ProtectedRoute element={
                <PageLayoutWrapper>
                  <MealIntroduce />
                </PageLayoutWrapper>
              } />
            </Suspense>
          }
        />
        <Route path="/lang"
          element={
            <Suspense fallback={<CircularProgress />}>
              <PageLayoutWrapper>
                <Lang />
              </PageLayoutWrapper>
            </Suspense>
          }
        />
        <Route path="/terms_of_use"
          element={
            <Suspense fallback={<CircularProgress />}>
              <PageLayoutWrapper>
                <TermsOfUse />
              </PageLayoutWrapper>
            </Suspense>
          }
        />
        <Route path="/privacy_policy"
          element={
            <Suspense fallback={<CircularProgress />}>
              <PageLayoutWrapper>
                <PrivacyPolicy />
              </PageLayoutWrapper>
            </Suspense>
          }
        />
        <Route path="/preview"
          element={
            <Suspense fallback={<CircularProgress />}>
              <PageLayoutWrapper>
                <Preview />
              </PageLayoutWrapper>
            </Suspense>
          }
        />
        {/* Homepage has sub routes, placed at the bottom and above the 404 page */}
        {
          auth.user ? (
            <Route path="/*" element={
              <ProtectedRoute element={
                <PageLayoutWrapper>
                  <HomePage />
                </PageLayoutWrapper>
              } />
            } />
          ) : (
            <Route path="/*" element={ <HomePageMain /> } />
          )
        }
        {/* <Route path="/*" element={ <PageLayoutWrapper children={ <Page404 /> } /> } /> */}
        {/* <Route path="/"
          element={
            // is Login
            <ProtectedRoute path="/" element={<HomePage /> } />
          }
        /> */}
      </Routes>
    </Router>
  );
}
