import { styled, Stack, Box } from "@mui/material";

export const PageStack = styled(Stack)(({ theme }) => ({
  width: "100%",
  maxWidth: theme.breakpoints.values.xl,
  height: "100%",
  margin: "0 auto",
  position:"relative"
}));

export const PageBox = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: theme.breakpoints.values.xl,
  height: "100%",
  margin: "0 auto",
  position:"relative"
}));
