import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../store';

export type AuthSliceType = {
  user: UserInfoType | null;
  access_token: string | null;
  refresh_token: string | null;
  access_token_expire_at: number;
  refresh_token_expire_at: number;
  registerInfo: RegisterAPIRequest | null;
};

const initialState: AuthSliceType = {
  user: null,
  access_token: null,
  refresh_token: null,
  access_token_expire_at: 0,
  refresh_token_expire_at: 0,
  registerInfo: null
};

const slice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setLogin(state: AuthSliceType, action: PayloadAction<AccountAPIResponse>) {
      const { user, access_token, refresh_token, access_token_expire_at, refresh_token_expire_at } = action.payload;
      if( typeof user !== "undefined" ){ state.user = user; }
      state.access_token = access_token;
      state.refresh_token = refresh_token;
      state.access_token_expire_at = access_token_expire_at;
      state.refresh_token_expire_at = refresh_token_expire_at;
    },
    setRegisterInfo(state: AuthSliceType, action: PayloadAction<RegisterAPIRequest | null>) {
      state.registerInfo = action.payload;
    },
    updateUserKey(state: AuthSliceType, action: PayloadAction<{ [key: string]: any }>){
      if(state.user){
        state.user = { ...state.user, ...action.payload };
      }
    },
    setUserInfo(state: AuthSliceType, action: PayloadAction<UserInfoType | null>){
      state.user = action.payload;
    },
    logout(state: AuthSliceType) {
      state.user = null;
      state.access_token = null;
      state.refresh_token = null;
      state.access_token_expire_at = 0;
      state.refresh_token_expire_at = 0;
    }
  },
});

export const {
  setLogin,
  setRegisterInfo,
  updateUserKey,
  setUserInfo,
  logout
} = slice.actions;

export const selectAuth = createSelector(
  [(state: RootState) => state.auth],
  (state) => state
);

export default slice.reducer;
