
import {
  Routes, Route
} from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/slice/AuthSlice";
import { useIntl } from "react-intl";
import { Messages } from "../localization/Messages";
import GeoduckFooder from "../components/fooder";

import Home from "./pageRouter/home";
import Library from "./pageRouter/library";
import Myprofile from "./pageRouter/myprofile";
import Favorite from "./pageRouter/favorite";
import { PageStack } from "../components/muiCustom/current";
import { useGetUserAPI } from "../redux/geoduckApi";

export default function HomePage(){
  const getUserAPI = useGetUserAPI();
  const dispatch = useDispatch();

  useEffect(()=>{
    getUserAPI({
      onSuccess: (res) => {
        dispatch( setUserInfo(res.data) );
      }
    })
  },[]);

  return (
    <PageStack>
      <Routes>
        <Route path="/library" element={<Library />} />
        <Route path="/favorite" element={<Favorite />} />
        <Route path="/myprofile" element={<Myprofile />} />
        <Route path="/*" element={<Home />} />
      </Routes>
      <GeoduckFooder />
    </PageStack>
  );
}