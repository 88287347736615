import {
  Box, Button, CardMedia, Stack, Typography, Grid
} from "@mui/material";
import { useCallback, useRef } from "react";
import { useIntl } from "react-intl";
import { Messages } from "../../localization/Messages";
import { ArrowRightIcon, LoadingIcon } from "../../assets/imgs/icons";
import {mediaRequestUrl} from '../../env';

export default function MediaList({
  title,
  list,
  load,
  allChange,
  checkChange,
}:{
  title:string,
  list: VideosAPIResponse[],
  load: boolean,
  allChange:()=>void,
  checkChange:(value: VideosAPIResponse)=>void
}){
  const intl = useIntl();
  const MediaCard = useCallback(({
    data
  }:{
    data: VideosAPIResponse
  })=>{
    const parentRef = useRef<HTMLDivElement>(null);
    const mxw = data.material.thumbnail ? "2.6rem" : "1.36rem";
    return (
      <Box flexShrink="0" ref={parentRef} sx={{maxWidth: mxw, cursor:"pointer"}}
        onClick={()=>{
          checkChange(data);
        }}>
        <Box height="1.36rem" borderRadius="0.08rem" overflow="hidden" position="relative">
          <CardMedia src={
            data.material.thumbnail ? mediaRequestUrl() + data.material.thumbnail : "/image/no-video.jpg"
          }
            sx={{width:"auto", height:"100%"}}
            component="img" onLoad={(e)=>{
              let tag = e.target as HTMLImageElement;
              let width = tag.width;
              parentRef.current!.style.width = width + "px";
            }}
          />
          { data.progress_bar !== "0.00" && data.progress_bar !== "1.00" && (
            <Stack position="absolute" left="0" bottom="0" width="100%" height="100%" bgcolor="rgba(0,0,0,0.2)"
              alignItems="center" justifyContent="center">
              <LoadingIcon className="geoduck-rotates_cartoon"
                sx={{ width:"0.6rem", height:"0.6rem" }} />
            </Stack>
          ) }
        </Box>
        <Typography fontSize="0.18rem" fontWeight="700" lineHeight="0.3rem" mt="0.08rem"
          className="ellipsis" sx={{ "&:hover": { color:"#235DFF" } }}>
          {data.name}
        </Typography>
      </Box>
    )
  },[]);

  return (
    <Box mb="0.16rem">
      <Stack direction="row" justifyContent="space-between" p="0 0.24rem 0.16rem">
        <Typography component="h4" fontSize="0.24rem"  fontWeight={700}>
          {title}
        </Typography>
        <Button sx={{p:"0"}} onClick={allChange} >
          <Typography component="span" color="#235DFF" fontSize="0.16rem" fontWeight={700}
            mr="0.16rem">
            {intl.formatMessage(Messages.viewAll)}
          </Typography>
          <ArrowRightIcon sx={{ width: "0.24rem", height: "0.24rem" }} fill="#235DFF" />
        </Button>
      </Stack>
      <Stack direction="row" p="0 0.24rem" sx={{overflowX:"auto"}} columnGap="0.16rem">
        {
          list.map((item, index) => <MediaCard data={ item } key={index} /> )
        }
        {
        list.length === 0 && !load && (
          <Stack alignItems="center" width="100%" rowGap="0.4rem">
            <CardMedia src="/background/not_found.png" sx={{width:"2rem", height:"2rem"}} component="img" />
            <Typography fontSize="0.24rem" fontWeight="700">
              {intl.formatMessage(Messages.empty)}
            </Typography>
          </Stack>
        )
        }
      </Stack>
    </Box>
  )
};