import {
  Stack, CardMedia, Box, styled,
  Typography, Popover, SxProps, Theme,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useIntl } from 'react-intl';

import { Messages } from '../../localization/Messages';
import { ButtonInfo, ButtonPrimary, ButtonText } from '../muiCustom/Button';
import {
  DeleteIcon,
  FavoriteIcon,
  FavoriteCheckIcon,
  DownloadIcon,
  LoadingIcon
} from '../../assets/imgs/icons';
import {mediaRequestUrl} from '../../env';

import { usePostFavoriteVideo } from '../../redux/geoduckApi';
import { Dialog } from '../muiCustom/dialog';

const MarginDiv = styled("div")({
  margin: "0.1rem 0",
  width:"100%",
  height:"0.01rem",
  backgroundColor:"#35383F"
});

type DeleteParamsType = (value: VideosAPIResponse, tag: HTMLElement | null, isFav?:boolean) => void;

const MoreItem = memo(({
  item,
  index,
  onChange,
  onDelete
}:{
  item: VideosAPIResponse;
  index: number;
  onChange?: (value: VideosAPIResponse) => void;
  onDelete: DeleteParamsType;
})=>{
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [data, setData] = useState(item);
  const navigate = useNavigate();
  const stackRef = useRef<HTMLDivElement>(null);

  const postFavorite = usePostFavoriteVideo();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const updateTime = useMemo(()=> (
    moment(data.updated_at).format("HH:mm:ss · MMM D, YYYY")
  ),[]);
  

  const PopverButton = useCallback(({
    icon,
    text,
    onClick,
  }:{
    icon: JSX.Element;
    text: string;
    onClick?: ()=>void;
  })=>{
    return (
      <ButtonText sx={{ p:"0", lineHeight: "0.4rem", gap: "0.12rem", width: "2.18rem", justifyContent: "flex-start" }} 
        onClick={onClick}>
        {icon}
        <Typography sx={{
          fontSize:"0.18rem", fontWeight:"600",
          color: text === "Delete" ? "#F75555" : "#FAFAFA"
        }}>
          {text}
        </Typography>
      </ButtonText>
    )
  },[data]);

  const updateFavorite = () => {
    let favorite = !data.favorite;
    setData({...data, favorite: favorite});
    postFavorite({
      body:{ video_id: data.id, favorite },
      onSuccess: (res) => {
        let pathname = window.location.pathname;
        if(pathname === "/favorite"){
          onDelete(data, stackRef.current, true);
        }
      }
    })
    handleClose();
  };
  const deleteVideos = ()=>{
    onDelete(item, stackRef.current);
    handleClose();
  };

  const downloadVideo = () => {
    if(data.download_path){
      navigate("/generate/" + btoa(data.download_path) );
    }else{
      window.location.href = "/video-edit?id=" + data.id;
    }
  };

  return (
    <Stack direction="row" p="0.12rem 0.24rem" gap="0.16rem" alignItems="center" ref={stackRef}
      sx={{
        cursor: "pointer",
        borderTop: index !== 0 ? "0.01rem solid #35383F" : "none",
        "&:hover": {
          backgroundColor: "#35383F",
        }
      }} >
      <Box flexShrink="0" sx={{ position:"relative",
        width: "1.2rem", height:"1.2rem", overflow:"hidden", borderRadius:"0.08rem"
      }}
        onClick={()=>{ onChange && onChange(item) }}>
        <CardMedia component="img"
          src={
            data.material.thumbnail ? mediaRequestUrl() + data.material.thumbnail : "/image/no-video.jpg"
          }
          sx={{ width: "1.2rem", height:"1.2rem" }} />
          { data.progress_bar !== "0.00" && data.progress_bar !== "1.00" && (
            <Stack position="absolute" left="0" bottom="0" width="100%" height="100%" bgcolor="rgba(0,0,0,0.2)"
              alignItems="center" justifyContent="center">
              <LoadingIcon className="geoduck-rotates_cartoon" sx={{ width:"0.6rem", height:"0.6rem" }} />
            </Stack>
          ) }
        
      </Box>
      <Box flexGrow="1" height="100%"
        onClick={()=>{ onChange && onChange(item) }}>
        <Typography fontSize="0.2rem" fontWeight="700" lineHeight="0.32rem" className='text-truncate'
          sx={{
            "&:hover": { color: "#235DFF" }
          }}>
          {item.name}
        </Typography>
        <Typography mt="0.12rem" color="#E0E0E0" fontSize="0.14rem" fontWeight="500">
          {updateTime}
        </Typography>
      </Box>
      <Box flexShrink="0">
        <IconButton sx={{p:"0"}} onClick={(e)=>{
          e.stopPropagation();
          handleClick(e);
        }}>
          <MoreVertIcon sx={{ width: "0.28rem", height: "0.28rem", color:"#FFF" }} />
        </IconButton>
        <Popover open={open} anchorEl={anchorEl} onClose={handleClose}
          sx={{
            ".MuiPaper-root":{
              bgcolor:"#262A35", borderRadius:"0.16rem",p:"0.14rem 0.2rem",
              backgroundImage: "none"
            }
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Stack>
            {
              data.material.thumbnail && (
                <>
                  <PopverButton icon={
                    <DownloadIcon sx={{ width: "0.24rem", height: "0.24rem" }} />
                  }
                  text={intl.formatMessage(Messages.Download)}
                  onClick={downloadVideo} />
                  <MarginDiv />
                </>
              )
            }
            <PopverButton
              icon={
                data.favorite ? (
                  <FavoriteCheckIcon sx={{ width: "0.24rem", height: "0.24rem" }} fill="#FF4A4A" />
                ) : (
                  <FavoriteIcon sx={{ width: "0.24rem", height: "0.24rem" }} />
                )
              }
              text={ intl.formatMessage(Messages[ data.favorite ? "RemoveFromFavorite" : "AddToFavorite"]) }
              onClick={updateFavorite} />
            <MarginDiv />
            <PopverButton icon={
              <DeleteIcon sx={{ width: "0.24rem", height: "0.24rem" }} />
            } text="Delete" onClick={deleteVideos} />
          </Stack>
        </Popover>
      </Box>
    </Stack>
  )
});



export default function MoreLists({
  list,
  onChange,
  onDelete
}:{
  list: VideosAPIResponse[];
  onChange?: (value: VideosAPIResponse) => void;
  onDelete?: (id: string, tag: HTMLElement | null, isFav?:boolean) => void;
}) {
  const [open, setOpen] = useState(false);
  const [cacheData, setCacheData] = useState<{data: VideosAPIResponse, tag: HTMLElement | null}>();
  const btnSx:SxProps<Theme> = {
    borderRadius: "0.2rem", flex: "1", fontSize:"0.14rem"
  };

  const cacheUpdate = (
    value: VideosAPIResponse, tag: HTMLElement | null, isFav?:boolean
  ) => {
    
    if(isFav){
      onDelete && onDelete(value.id, tag, isFav);
    }else{
      setCacheData({data: value, tag });
      setOpen(true);
    }
  }

  const deleteConfirm = () => {
    if( cacheData && onDelete ){
      onDelete(cacheData.data.id, cacheData.tag);
    }
    setOpen(false);
  }
  return (
    <Stack>
      <Dialog open={ open } onClose={ ()=>{ setOpen(false) } }>
        <Stack bgcolor="rgb(31, 34, 42)" sx={{ p:"0.2rem 0.2rem 0.2rem", borderRadius:"0.1rem" }}
          alignItems="center" >
          <Box sx={{ borderBottom:"0.01rem solid rgb(53,56,63)", width:"100%", pb:"0.1rem" }}>
            <Typography sx={{ fontWeight: "700", fontSize:"0.2rem", textAlign:"center" }}>
              Warning
            </Typography>
          </Box>
          <Box sx={{ padding: "0.24rem 0 0.3rem" }}>
            <Typography sx={{ fontSize: "0.16rem" }}>
              Are you sure you want to delete this { cacheData?.data.material.thumbnail ? "video" : "script" }?
            </Typography>
          </Box>
          <Stack direction="row" gap="0.1rem" width="100%">
            <ButtonInfo sx={btnSx} onClick={ ()=>{ setOpen(false) } }>Cancel</ButtonInfo>
            <ButtonPrimary sx={btnSx} onClick={ deleteConfirm }>Confirm</ButtonPrimary>
          </Stack>
        </Stack>
      </Dialog>
      {
        list.map((item, index) => (
          <MoreItem key={item.id} index={index} item={item} onChange={onChange} onDelete={cacheUpdate} />
        ))
      }
    </Stack>
  )
};
