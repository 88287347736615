import { useDispatch } from "react-redux";
import { store } from "./store";
import { logout, setLogin } from "./slice/AuthSlice";

import { envResolverAPIURL } from "../env";
import { useGetRefreshToken } from "../hooks/userHook";
import { showMessage } from "../components/muiCustom/messageTip";

type APIFunctionServer<T,P> = {
  body?: T,
  newUrl?: string,
  onSuccess?: (res: APIReturnServer<P>) => void,
  onError?: (err: any) => void,
  onFinally?: () => void
  notTip?: boolean
}

function createImmeditFetch<T,P>({
  url,
  method = 'GET',
  headers,
}:{
  url: string,
  method?: string,
  headers?: {[key: string]: string},
}){
  const headUrl = envResolverAPIURL();

  const refresh_token = useGetRefreshToken();
  const dispatch = useDispatch();
  
  function post_update_tokens(arg: APIFunctionServer<T,P> ){
    fetch(headUrl + 'refresh_token', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ refresh_token }),
    }).then((res)=>{
      if(res.ok){ return res.json(); }else{ return Promise.resolve({status_code: 401}); }
    }).then((res) => {
      if(res.status_code === 200){
        dispatch( setLogin(res.data) );
        initiate_request(arg, true);
      }else{
        dispatch( logout() );
      }
    }).catch((err) => {
      dispatch( logout() );
    });
  }
  
  function initiate_request(
    { body, newUrl, onSuccess, onError, onFinally, notTip }:APIFunctionServer<T,P>,
    isRefresh?: boolean,
  ){
    const access_token = store.getState().auth.access_token;

    const controller = new AbortController();
    const newHeader = new Headers(headers);
    if(access_token){
      newHeader.set( "Authorization", "Bearer " + access_token )
    };

    let newBody = "" as FormData | string;
    const typeData = Object.prototype.toString.call(body);
    if(typeData === '[object Object]'){
      if(method.toLowerCase() === "get" && body){
        newHeader.set('Content-Type', 'application/x-www-form-urlencoded');
        newBody = Object.keys(body).map((key) => key + '=' + (body as any)[key]).join('&');
        url += "?" + newBody;
      }else{
        newHeader.set('Content-Type', 'application/json');
        newBody = JSON.stringify(body);
      }
    }else if(typeData === '[object FormData]'){
      newBody = body as FormData;
    };
    
    let rejectUrl = newUrl || url;
    fetch(headUrl + rejectUrl, {
      method,
      headers: newHeader,
      ...body && method.toLowerCase() !== "get" ? {body: newBody} : {},
      credentials: 'include',
      signal: controller.signal,
    }).then((res) =>{
      if(!res.ok){
        return Promise.reject(res);
      }else{
        return res.json();
      }
    }).then((res: APIReturnServer<P>) => {
      if(res.status_code === 401 && isRefresh){
        dispatch( logout() );
      }else if(res.status_code === 401 && !isRefresh){
        post_update_tokens({body, newUrl, onSuccess, onError, onFinally, notTip});
      }else if(res.status_code !== 200){
        onError && onError(res);
        !notTip && showMessage.error(res.message);
      }else{
        onSuccess && onSuccess(res);
      }
    }).catch((err:Response) => {
      if(err.status === 401 && isRefresh){
        dispatch( logout() );
      }else if(err.status === 401 && !isRefresh){
        post_update_tokens({body, newUrl, onSuccess, onError, onFinally});
      }else{
        onError && onError(err);
      }
    }).finally(() => {
      onFinally && onFinally();
    });
    return {
      cancel: () => { controller.abort(); }
    }
  };
  return initiate_request;
};

// API
export const usePost= function(){
  return createImmeditFetch<{},{}>({ method:"POST", url: '' })
};
export const useGetVoices = function(){
  return createImmeditFetch<undefined, VoicesAPIResponse>({ method:"Get", url: 'voices' })
};
export const useGetLanguages = function(){
  return createImmeditFetch<undefined, LanguagesAPIResponse>({ method:"Get", url: 'languages' })
};
export const usePostCreateScript = function(){
  return createImmeditFetch<CreateScriptAPIRequest, CreateScriptAPIResponse>({ method:"POST", url: 'create_script' })
};
export const usePostCreateVideo = function(){
  return createImmeditFetch<CreateVideoAPIRequest, CreateVideoAPIResponse>({ method:"POST", url: 'create_video' })
};

export const usePostGenerateVideo = function(){
  return createImmeditFetch<FormData,{sessionid: string}>({ method:"POST", url: 'generate_video' })
};

export const usePostVideoSynthesis = function(){
  return createImmeditFetch<FormData,{sessionid: string}>({ method:"POST", url: 'video_synthesis' })
};
export const useGetVideos = function(){
  return createImmeditFetch<null,VideosAPIResponse[] | null>({ method:"GET", url: 'videos' })
};

export const usePostRecordOp = function(){
  return createImmeditFetch<RecordOpAPIType, {video_id: string}>({ method:"POST", url: 'record_op' })
};
export const useGetRecordOp = function(){
  return createImmeditFetch<{video_id: string}, RecordOpAPIType>({ method:"GET", url: 'record_op' })
};
// account
export const usePostLogin = function(){
  return createImmeditFetch<LoginAPIRequest, AccountAPIResponse>({ method:"POST", url: 'login' })
};
export const usePostRegister = function(){
  return createImmeditFetch<RegisterAPIRequest, AccountAPIResponse>({ method:"POST", url: 'register' })
};
export const usePostEmailCaptchaSend = function(){
  return createImmeditFetch<EmailCaptchaSendAPIRequest, {email: string}>({ method:"POST", url: 'email_captcha_send' })
};
export const usePostEmailCaptchaVerify = function(){
  return createImmeditFetch<EmailCaptchaVerifyAPIRequest, {cert: string,email:string}>({ method:"POST", url: 'email_captcha_verify' })
};

export const usePostFavoriteVideo = function(){
  return createImmeditFetch<FavoriteVideoAPI, FavoriteVideoAPI>({ method:"POST", url: 'favorite_video' })
};

export const useGetFavoriteVideo = function(){
  return createImmeditFetch<{favorite: boolean}, VideosAPIResponse[] | null>({ method:"GET", url: 'favorite_video' })
};

export const useDeleteVideos = function(){
  return createImmeditFetch<{video_id: string}, {}>({ method:"DELETE", url: 'videos' })
};

export const useGetVideo = function(){
  return createImmeditFetch<
    {video_id: string}, GetVideoAPIResponse
  >({ method:"GET", url: 'video' })
};

export const usePostVideoStyleSave = function(){
  return createImmeditFetch<
    {video_id: string, info: string}, {video_id: string}
  >({ method:"POST", url: 'video_style_save' })
};

export const usePostLogout = function(){
  return createImmeditFetch<{refresh_token: string}, {}>({ method:"POST", url: 'logout' })
};
export const usePostResetPassword = function(){
  return createImmeditFetch<{ new_password: string, cert: string }, null>({ method:"POST", url: 'reset_password' })
};

export const usePostResetUserinfo = function(){
  return createImmeditFetch<{ username: string }, null>({ method:"POST", url: 'reset_userinfo' })
};

// custom scene api
export const useGetScene = function(){
  return createImmeditFetch<undefined, SceneAPIResponse[] | null>({ method:"GET", url: 'scenes' })
};
export const usePostScene = function(){
  return createImmeditFetch<SceneAPIRequest, SceneAPIResponse>({ method:"POST", url: 'scenes' })
};
export const useDeleteScene = function(){
  return createImmeditFetch<{id: string}, {}>({ method:"DELETE", url: 'scenes' })
};
export const usePutScene = function(){
  return createImmeditFetch<SceneAPIRequest, SceneAPIResponse>({ method:"PUT", url: 'scenes' })
};

export const useGetStripePlan = function(){
  return createImmeditFetch<undefined, StripePlanAPIResponse>({ method:"GET", url: 'stripe/plan' })
};

export const usePostStripeCreateCheckoutSession = function(){
  return createImmeditFetch<{ subscription_key: string }, {redirect_url: string} | null>(
    { method:"POST", url: 'stripe/create_checkout_session' }
  )
};

export const useGetUserAPI = function(){
  return createImmeditFetch<undefined, UserInfoType>({ method:"GET", url: 'user' })
};

export const useGetAffiliateLink = function(){
  return createImmeditFetch<undefined, {affiliate_code: string}>({ method:"GET", url: 'affiliate/link' })
};
export const usePostAffiliateAccessRecord = function(){
  return createImmeditFetch<AffiliateAccessRecordAPIRequest, null>({ method:"POST", url: 'affiliate/access_record' })
};
export const useGetAffiliateRewards = function(){
  return createImmeditFetch<undefined, AffiliateRewardsAPIResponse>(
    { method:"GET", url: 'affiliate/rewards' }
  )
};
export const useGetAudios = function(){
  return createImmeditFetch<{mood: string}, AudiosAPIResponse[] | null>(
    { method:"GET", url: 'audios' }
  )
};

export const useGetStripePortal = function(){
  return createImmeditFetch<undefined, { url: string }>({ method:"GET", url: 'stripe/portal' })
};

export const usePostForgetPassword = function(){
  return createImmeditFetch<ForgotPasswordAPIRequest, null>({ method:"POST", url: 'forget_password' })
};

export const usePostVideoShare = function(){
  return createImmeditFetch<VideoShareAPIRequest, {info: string}>({ method:"POST", url: 'video_share' })
};

export const useGetVideoShare = function(){
  return createImmeditFetch<{video_id:string}, {info: string}>({ method:"GET", url: 'video_share' })
};

export const useGetStyleList = function(){
  return createImmeditFetch<{video_id:string}, StyleListAPIResponse[]>({ method:"GET", url: 'style_list' })
};
