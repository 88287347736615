import {
  useState, useCallback, useImperativeHandle, forwardRef, createRef, useRef
} from "react";
import {
  Box, Stack, Typography, CardMedia, IconButton, Fade, Collapse
} from "@mui/material";
import {
  createStyles, makeStyles
} from "@mui/styles";
import {
  CrossIcon
} from "../../assets/imgs/icons";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

const SHOWMESSAGE_DURATION = 3000;
type SeverityType = 'success' | 'error';
type ReactActions<T> = React.Dispatch<React.SetStateAction<T>>;
type MsgTypes = {
  top?: string;
  message: string;
  severity: SeverityType;
}

export const MessageRef: React.RefObject<{
  show: fnVoidToVoid;
  hide: fnVoidToVoid;
  message: ReactActions<MsgTypes>;
  severity: ReactActions<SeverityType>;
}> = createRef();

const TipTextureMessage = forwardRef((_: any, ref: any)=>{
  const [open, setOpen] = useState(false);
  const styles = useStyles();
  const nodeRef = useRef(null);
  const [message, setMessage] = useState<MsgTypes>({
    top: "10%",
    message: "",
    severity: "success"
  });

  const show = useCallback(() => setOpen(true), []);
  const hide = useCallback(() => setOpen(false), []);
  useImperativeHandle(ref, () => ({ show, hide, message: setMessage }));

  const typogStyle = {
    color: "#FAFAFA", fontSize: "0.16rem", fontWeight: "500", lineHeight: "0.2rem"
  };
  
  return (
    <Fade in={open} mountOnEnter unmountOnExit>
      <Stack direction="row" className={`${styles.rootTips}`} ref={nodeRef} justifyContent="center"
        sx={{ top: message.top, maxWidth:"5rem", width:"80%" }}>
        <Stack direction="row" p="0.1rem" className={`${styles.tipsContent}`} alignItems="center"
          justifyContent="center">
          {
            message.severity === "success" ?
              <CheckCircleRoundedIcon sx={{ color: "#00DD00", fontSize: "0.2rem" }} /> :
              <ErrorRoundedIcon sx={{ color: "#DD0000", fontSize: "0.2rem" }} />
          }
          <Typography component="p" sx={typogStyle} ml="0.1rem"> { message.message } </Typography>
        </Stack>
      </Stack>
    </Fade>
  );
})

export const MessageTip = () => <TipTextureMessage ref={MessageRef} />;

let timer: NodeJS.Timeout;
function Message(
  message: MsgTypes["message"],
  top?: string,
  severity: SeverityType = 'success',
  duration = SHOWMESSAGE_DURATION
) {
  try {
    MessageRef.current?.show();
    MessageRef.current?.message({
      top,
      message,
      severity
    });
    clearTimeout(timer);
    timer = setTimeout(() => {
      MessageRef.current?.hide();
    }, duration);
  } catch {}
}

export const showMessage = {
  success: function(message: MsgTypes["message"], top?: string) {
    Message(message, top, "success");
  },
  error: function(message: MsgTypes["message"], top?: string) {
    Message(message, top, "error");
  } 
};

const useStyles = makeStyles(() =>
  createStyles({
    rootTips: {
      position:"fixed",
      top: "50%",
      zIndex: 987654321,
      left: "50%",
      transform: "translateX(-50%)",
      overflow: "hidden",
    },
    tipsContent:{
      backgroundColor: "rgba(0,0,0,0.8)",
      borderRadius: "0.06rem",
    }
  })
);
