import {
  styled,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  Stack, Typography, CardMedia,
  Button
} from "@mui/material";
import { ButtonPrimary } from "./Button";
import { useIntl } from "react-intl";
import { Messages } from "../../localization/Messages";
import { UpgradeIcon } from "../../assets/imgs/icons";
import { useNavigate } from "react-router-dom";

export const Dialog = styled(({...props}: MuiDialogProps)=>(
  <MuiDialog {...props} />
))({
  ".MuiBackdrop-root": {
    backdropFilter: "blur(2px)"
  },
  ".MuiPaper-root":{
    backgroundColor: "transparent",
    boxShadow: "none",
    backgroundImage: "none",
    maxWidth: "100%",
  }
});

export function CopySuccessDialog({
  open,
  setOpen
}:{
  open: boolean;
  setOpen: (value: boolean) => void;
}){
  const intl = useIntl();
  return (
    <Dialog open={open} onClose={()=>setOpen(false)}>
      <Stack sx={{ background: "linear-gradient(180deg, #235DFF 0%, #7198ff 80%)",
        padding: "0.2rem 0.6rem", borderRadius: "0.2rem", position: "relative"
      }}>
        <CardMedia component="img" image="/image/copy-success.png"
          sx={{ width: "2rem" }} />
        <Typography sx={{ m:"0.3rem 0 0.3rem",
          color: "#FFF", fontWeight: "700", fontSize:"0.24rem", textAlign:"center"
        }}>
          {intl.formatMessage(Messages.copySuccess)}
        </Typography>
        <Button onClick={()=>setOpen(false)}
          sx={{
            p:"0.06rem 0.2rem", fontSize:"0.16rem", borderRadius:"0.4rem", color:"#235DFF",
            bgcolor: "#FFF", "&:hover": { bgcolor: "#FFF" }
          }}>
          {intl.formatMessage(Messages.OK)}
        </Button>
      </Stack>
    </Dialog>
  )
}

export function PlanUpgradeDialog({
  open,
  setOpen,
  content
}:{
  open: boolean;
  setOpen: (value: boolean) => void;
  content: string;
}){
  const intl = useIntl();
  const navigate = useNavigate();
  
  return (
    <Dialog open={open} onClose={()=>setOpen(false)}>
      <Stack sx={{ background: "linear-gradient(180deg, #235DFF 0%, #7198ff 80%)",
        padding: "0.2rem 0.3rem", borderRadius: "0.2rem", position: "relative",
        alignItems: "center"
      }}>
        <UpgradeIcon sx={{ width: "1.65rem", height:"1.3rem" }} />
        <Typography sx={{ m:"0.16rem 0 0.08rem",
          color: "#FFF", fontWeight: "700", fontSize:"0.24rem", textAlign:"center"
        }}>
          {intl.formatMessage(Messages.UnlockMoreContent)}
        </Typography>
        <Typography sx={{ m:"0 0 0.3rem", maxWidth:"3.4rem",
          color: "#FFF", fontSize:"0.14rem", textAlign:"center"
        }}>
          {intl.formatMessage(Messages.planLimitReached)}<br />
          {content}
        </Typography>
        
        <Button onClick={()=>{
          navigate("/plan")
        }}
          sx={{
            width: "100%",
            p:"0.06rem 0.2rem", fontSize:"0.16rem", borderRadius:"0.4rem", color:"#235DFF",
            bgcolor: "#FFF", "&:hover": { bgcolor: "#FFF" }
          }}>
          {intl.formatMessage(Messages.Upgrade)}
        </Button>
      </Stack>
    </Dialog>
  )
}

