import {
  createTheme,
  PaletteOptions,
  responsiveFontSizes,
  BreakpointsOptions,
} from '@mui/material/styles';
import Color from 'color';
import { useThemeMode } from '../../hooks/theme';

const spacingUnit = 8;
const fontFamily = 'Urbanist';

/**
 * Rewrite MUI's built-in color style
 * Usage: theme.palette.primary.main ...
 */

const fnThemeConfig = (mode: 'light' | 'dark') => {
  const M = (l: string, d?: string) => (mode === 'light' ? l : d || l);

  return {
    palette: {
      mode,
      primary: {
        main: M('#FFF'),
        main_white: M('#694CE6', '#FFFFFF'),
        main15: M('rgba(105, 76, 230, 0.15)'),
        main50: M('rgba(105, 76, 230, 0.5)', 'rgba(255,255,255,0.5)'),
        light: 'rgb(123, 99, 232)',
        contrastText: '#fff',
        '2': M('#F0EDFD80', '#35383F'),
        '2_1': M('#F0EDFD80', '#1F222A'),
        '2_2': M('#F0EDFD80', '#342F46'),
        '25': M('#F0EDFD', '#35383F'),
        '25_25': M('#F0EDFD', '#F0EDFD'),
        '50': M('#E1DBFA'),
        '50_black': M('#E1DBFA', '#1F222A'),
        '100': M('#D2C9F8'),
        '200': M('#C3B7F5'),
        '300': M('#B4A6F3'),
        '400': M('#9682EE', '#211D35'),
        '400_1': M('#9682EE', '#9682EE'),
        '400_2': M('#9682EE', '#342F46'),
        '500': M('#785EE9'),
        '600': M('#694CE6', '#fff'),
        '600_100': M('#694CE6', '#D2C9F8'),
        '600_300': M('#694CE6', '#B4A6F3'),
        '600_200': M('#694CE6', '#C3B7F5'),
        '700': M('#5F44CF', '#fff'),
        '700_2': M('#5F44CF', '#5F44CF'),
        '800': M('#4A35A1'),
        '950': M('#352673', '#fff'),
      },
      secondary: {
        main: M('#B0FF92'),
        '500': M('#B8FF9D'),
        '600': M('#B0FF92'),
        '700': M('#9EE683'),
        '950': M('#354C2C'),
      },
      grey: {
        white_600: M('#fff', '#35383F'),
        '25': M('#9591A6', '#A9A6BA'),
        '50': M('#645E7D', '#9591A6'),
        '50_2': M('#645E7D', '#fff'),
        '100': M('#35383F', '#fff'),
        '200': M('#35383F'),
        '200_white': M('#35383F', '#fff'),
        '300': '#e0e0e0',
        '400': M('#373348', '#A9A6BA'),
        '500': M('#342F46'),
        '500_700': M('#342F46', '#FAFAFA'),
        '600': M('#35383F'),
        '700': M('#292636', '#FAFAFA'),
        '700_700': M('#292636', '#292636'),
        '800': '#424242',
        '900': M('#212121', '#fff'),
        '900_1': M('#212121', '#212121'),
        '950': M('#191528', '#fff'),
        black_25: M('#3B3847', '#9591A6'),
        black_white: M('#29224F', '#fff'),
        black_lightgrey: M('#29224F', '#DDDBE6'),
        disabled_bg: M('rgba(0,0,0,0.12)', 'rgba(255,255,255,0.12)'),
        disabled_text: M('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.5)'),
        lightGrey_darkGrey: M('#f7f7f7', '#191528'),
      },
      neutral: {
        '0_0': M('#fff'),
        '0': M('#fff', '#191528'),
        '25': M('#FCFCFD', '#191528'),
        '25_2': M('#FCFCFD', '#211D35'),
        '50': M('#F9F9FB', '#211D35'),
        '50_2': M('#F9F9FB', '#1F222A'),
        '200': M('#FAFAFA', '#35383F'),
        '200_2': M('#FAFAFA', '#1F222A'),
        '300': M('#F7F7F8', '#342F46'),
        '400': M('#F5F5F5', '#342F46'),
        '500': M('#EFEFF1', '#342F46'),
        '600': M('#EBEAF0', '#342F46'),
        '600_1': M('#EBEAF0', '#35383F'),
        '700': M('#DDDBE6', 'transparent'),
        '700_1': M('#DDDBE6', '#35383F'),
        '800': M('#A9A6BA', '#1F222A'),
        '950': M('#726B8E', '#A9A6BA'),
      },
      danger: {
        '100': M('#FFF2F0'),
        '300': M('#FEB8AE'),
        '400': M('#FD5E49'),
        '500': M('#F15146'),
        '600': M('#F04438'),
        '700': M('#E33D72'),
      },
      base: {
        white: M('#ffffff', '#181A20'),
        white_2: M('#ffffff', '#373348'),
        white_3: M('#ffffff', '#DDDBE6'),
        white_black: M('#ffffff', '#000'),
        whiteonly: '#fff',
        white2white: M('#ffffff', '#ffffff'),
        main2white: M('#694CE6', '#ffffff'),
        lightblue2main: M('#F0EDFD', '#694CE6'),
        white2main: M('#ffffff', '#694CE6'),
        black: M('#000000', '#fff'),
        '2': M('#FEFEFE', '#fff'),
        '3': M('#FEFEFE', '#35383F'),
        '25': M('#F9F9FB', '#342F46'),
        '25_2': M('#F9F9FB', '#1F222A'),
        '50': M('#191528', '#DDDBE6'),
        '100': M('#645E7D', '#A9A6BA'),
        '50_50': M('#191528', '#191528'),
        dark2white: M('#191528', '#ffffff'),

      },
      text: {
        disabled: M('#FAFAFA', '#FAFAFA'),
      },
      warning: {
        '25': M('transparent'),
        '50': M('#FFF8EB'),
        '100': M('#FFE4C0'),
        '500': M('#FFAE43'),
        '600': M('#EEA23E'),
        '700': M('#D78100'),
      },
      success: {
        main: M('#41AE49'),
        '50': M('#F0FAF0'),
        '100': M('#C9EBCB'),
        '500': M('#5DC264'),
        '600': M('#41AE49'),
        '700': M('#2D8A39'),
        '800': M('#27682C'),
        '900': M('#1A451D'),
      },
      info: {
        main: M('#694CE6', '#fff'),
      },
      action: {
        active: M('rgba(0, 0, 0, 0.54)'),
        hover: M('#F9F8FE'),
        selected: M('rgba(0, 0, 0, 0.08)'),
        selectedOpacity: 0.08,
        disabled: M('#FAFAFA'),
        disabledBackground: M('#35383F'),
        disabledOpacity: 0.38,
        focus: M('rgba(0, 0, 0, 0.12)'),
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
        bgPrimary: M('rgba(248,182,255,0.2)'),
      },
      gradient: {
        overlayColor: M('rgba(105, 76, 230, 0.8)'),
      },
      background: {
        default: M('#FFF', '#181A20'),
      }
    },
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1080,
        xl: 1216,
        homepage: 1216,
      },
      unit: 'px',
    },
    typography: {
      fontFamily: fontFamily,
    },
    spacing: spacingUnit,
    shape: {
      borderRadius: 0,
    },
    zIndex: {
      modal: 1000,
    },
    shadow: {
      sxm: '0px 1px 2px ' + M(`rgba(25, 21, 40, 0.04)`),
    },
    custom: {
      lightBackground: '#ffffff',
      lightText: '#000000',
      lightBlueBackground: '#5b3de3',
      lightGreyText: 'rgba(16,16,16,0.5)',
      lightestGrey: '#ebebeb',
      iconAddColor: 'rgba(26,188,254,0.8)',
      iconDeleteColor: 'rgba(255,144,98,0.8)',
      iconAddPhotoColor: 'rgba(162,89,255,0.8)',
      AddressBGCoverColor: 'rgba(21,101,216,0.9)',
      quoteSignColor: '#00DAF7',
      primaryMainO6: 'rgba(91,61,227,0.06)',
      iconAccelerate: '#9EE334',
      iconCheck: '#FB912F',
      iconStar: '#FFDC27',
      darkGreen: '#009A50',
      iconStar2: '#EAC60B',
      tagBoxShadow: 'rgba(170, 170, 170, 0.25)',
      primary2: '#8B76EB',
      primary3: '#C7BDF6',
      primary4: M('#EBE7FC', '#1F222A'), // global border color
      primary5: M('#F9F8FE', '#35383F'),
      primary6: '#F9F7FF',
      almostWhite: '#FDFDFF',
      tagLine: 'rgba(56,27,189,1)',
      black: 'rgba(6,3,21,1)',
      black2: '#29224F',
      black2_008: 'rgba(41, 34, 79, 0.08)',
      gray: 'rgba(130,129,136,1)',
      lightGray: '#f7f7f7',
      grayNumber: '#ACABB1',
      lightRed: '#E33D72',
      filterText: 'rgba(63,42,158)',
      charts1: '#FFBA4A',
      charts2: '#41E2B9',
      charts3: '#9B5DE6',
      charts4: '#DB136C',
      charts5: '#279BF2',
      charts6: '#B563F0',
      charts7: '#689AF0',
      charts8: '#FFE060',
      charts9: '#FB912F',
      charts10: '#009A50',
      forte1: '#D0FFBE',
      forte2: '#DDDBE6',
      forte3: '#FEB8AE',
      forte4: '#C3B7F5',
      forte0: '#FFD08A',
      darkBlue: '#30207C',
      lucky: '#075535',
      google: '#4285F4',
      active: '#16b56b',
      drafted: '#e6ab09',
      completed: '#113ed4',
    },
  };
};

const themeConfigForModule = fnThemeConfig('dark');
declare module '@mui/material/styles' {
  interface Theme {
    palette: typeof themeConfigForModule.palette;
    shadow: typeof themeConfigForModule.shadow;
    custom: typeof themeConfigForModule.custom;
  }
  interface ThemeOptions {
    palett?: typeof themeConfigForModule.palette;
    shadow?: typeof themeConfigForModule.shadow;
    custom?: typeof themeConfigForModule.custom;
  }
}

function useTheme() {
  // const themeMode = useThemeMode();
  const themeMode = "dark";
  const themeConfig = fnThemeConfig(themeMode);

  let theme = createTheme({
    palette: themeConfig.palette as PaletteOptions,
    breakpoints: themeConfig.breakpoints as BreakpointsOptions,
    typography: themeConfig.typography,
    spacing: themeConfig.spacing,
    shape: themeConfig.shape,
    shadow: themeConfig.shadow,
    custom: themeConfig.custom,
    components: {
      MuiTableBody: {
        styleOverrides: {
          root: {
            '& .MuiTableRow-root:hover': {
              backgroundColor: `${themeConfig.palette.warning[25]} !important`,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: '0.48rem',
            fontWeight: 400,
            fontSize: '0.14rem',
          },
          input: {
            '&::placeholder': {
              fontSize: '0.14rem',
              color: `${themeConfig.palette.grey['50']} !important`,
              opacity: '1',
            },
            '&:-internal-autofill-selected': {
              // remove input:-internal-autofill-selected
              boxShadow: `inset 0 0 0 1000px ${themeConfig.palette.base[25]} !important`,
              borderRadius: '0px !important',
            },
            '&:autofill': {
              // remove input:-internal-autofill-selected
              boxShadow: `inset 0 0 0 1000px ${themeConfig.palette.base[25]} !important`,
              borderRadius: '0px !important',
            },
            '&.Mui-disabled': {
              textFillColor: `${themeConfig.palette.grey[50]} !important`,
            },
            paddingTop: '0.08rem !important',
            paddingBottom: '0.08rem !important',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '0.08rem',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: '0.06rem',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: `0.01rem solid transparent`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `0.01rem solid transparent`,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              border: `0rem solid transparent`,
            },
            backgroundColor: themeConfig.palette.base['25_2'],
          },
          notchedOutline: {
            border: `0.01rem solid transparent`,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            fontSize: '0.14rem',
            color: themeConfig.palette.grey[700],
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            fontSize: '0.14rem',
            color: themeConfig.palette.base.main2white,
            backgroundColor: themeConfig.palette.base.lightblue2main,
            borderRadius: '0.04rem',
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            padding: '0 !important',
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            '&>.MuiPaper-root': {
              background: 'transparent !important',
              boxShadow: '0 0 0 0 !important',
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            '&>li': {
              borderLeft: `0.01rem solid ${themeConfig.palette.neutral['700_1']}`,
              borderRight: `0.01rem solid ${themeConfig.palette.neutral['700_1']}`,
            },
            '&>li:last-of-type': {
              borderBottom: `0.01rem solid ${themeConfig.palette.neutral['700_1']}`,
              borderBottomLeftRadius: '0.1rem',
              borderBottomRightRadius: '0.1rem',
              borderTop: '0 !important',
            },
            '&>li:first-of-type': {
              borderTop: `0.01rem solid ${themeConfig.palette.neutral['700_1']}`,
              borderTopLeftRadius: '0.1rem',
              borderTopRightRadius: '0.1rem',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '0.1rem',
            fontWeight: 400,
            fontSize: '0.14rem',
            color: themeConfig.palette.grey[700],
            height: '0.4rem',
            backgroundColor: themeConfig.palette.base.white,
            '&.Mui-selected': {
              backgroundColor: `${themeConfig.custom.primary5} !important`,
            },
            ':hover': {
              color: `${themeConfig.palette.grey[700]} !important`,
              backgroundColor: `${themeConfig.custom.primary5} !important`,
            },
            '&.Mui-focusVisible': {
              backgroundColor: `${themeConfig.custom.primary5} !important`,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: {
            boxShadow: `0px 1px 2px rgba(25, 21, 40, 0.04)`,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: `0.04rem`,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              opacity: '1',
              filter: 'grayscale(30%)',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 600,
            boxShadow: 'none',
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: "transparent",
            },
            '&.Mui-disabled': {
              backgroundColor: themeConfig.palette.grey.disabled_bg,
              opacity: '1',
              filter: 'grayscale(30%)',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            fontWeight: 400,
            fontSize: '15px',
            color: themeConfig.palette.grey['50_2'],
          },
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);
  return theme;
}

export { useTheme };
