import { Stack, Box, Typography, CardMedia, Grid, Button, Link } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PageBox, PageStack } from "../components/muiCustom/current";
import PageMainHeader from "../components/header/pageMainHeader";
import { useBreakpointSm, useBreakpointMd } from "../utils/useStyleHooks";
import { PageInput } from "../components/muiCustom/input";
import { ButtonText } from "../components/muiCustom/Button";
import { PageSwiper } from "../components/muiCustom/swipers";
import MealList from "./meal/mealList";
import {
  CircleDashedIcon, CreativityIcon, PrecisionIcon, SpeedIcon, ArtistryIcon
} from "../assets/imgs/icons";

export default function HomePageMain(){
  const isSm = useBreakpointSm();
  const isMd = useBreakpointMd();
  const navigate = useNavigate();

  const iconStyles = useMemo(()=>({width:"0.8rem", height: "0.8rem"}),[])

  const discoverList = useMemo(()=>([
    { id: 1, name: "Creativity", desc: "Let your imagination run wild with endless creative stories.", icon: <CreativityIcon sx={iconStyles} /> },
    { id: 2, name: "Precision", desc: "Edit with pinpoint precision for perfect results.", icon: <PrecisionIcon sx={iconStyles} /> },
    { id: 3, name: "Speed", desc: "Create stunning videos in a fraction of the time.", icon: <SpeedIcon sx={iconStyles} /> },
    { id: 4, name: "Artistry", desc: "Craft visually captivating videos with ease.", icon: <ArtistryIcon sx={iconStyles} /> },
  ]),[]);

  const componentSx = useMemo(()=>({
    stackIcon: isSm ? {width:"1rem", height:"1rem"} : {width:"1.44rem", height:"1.44rem"},
    pictures: isSm ? {width:"100%", borderRadius:"0.2rem"} : {width:"6rem", borderRadius:"0.4rem"},
    isRow: isMd ? {flexDirection:"column"} : {flexDirection:"row"},
    "fs4": isSm ? "0.3rem" : "0.4rem",

    "bgStyle1": isSm ? {
      width:"3.1rem", height:"3.1rem", left:"50%", top:"0.39rem", filter: "blur(0.4rem)"
    }:{
      width:"4.2rem", height:"4.2rem", left:"50%", top:"0.61rem", filter: "blur(0.6rem)"
    },
    "bgStyle2": isSm ? {
      width:"2.42rem", height:"2.42rem", left:"0", top:"2.9rem", filter: "blur(0.4rem)"
    }:{
      width:"2.86rem", height:"2.86rem", left:"0", top:"3.1rem", filter: "blur(0.4rem)"
    },
    "bgStyle3": isSm ? {
      width:"2.21rem", height:"2.21rem", right:"0", top:"3.03rem", filter: "blur(0.4rem)"
    }:{
      width:"3.07rem", height:"3.07rem", right:"0", top:"2.86rem", filter: "blur(0.4rem)"
    },
    "bgStyle4": isSm ? {
      width:"2rem", height:"2rem", left:"0.8rem", top:"12.6rem", filter: "blur(0.4rem)"
    }:{
      width:"5rem", height:"5rem", left:"2.7rem", top:"12.7rem", filter: "blur(0.8rem)"
    },
    "bgStyle5": isSm ? {
      width:"2.7rem", height:"2.7rem", right:"-1.3rem", top:"18.3rem", filter: "blur(0.4rem)"
    }:{
      width:"5rem", height:"5rem", right:"-1.3rem", top:"16.9rem", filter: "blur(0.8rem)"
    }
  }),[isSm, isMd]);

  const jumpHrefUrl = (value:string)=>{
    let valueText = value.trim();
    if(!valueText) return;
    sessionStorage.setItem("videoText", valueText);
    navigate(`/account/login`);
  }
  const paymantChange = (id: string)=>{
    sessionStorage.setItem("paymentId", id);
    navigate(`/account/login`);
  };

  useEffect(()=>{
    sessionStorage.removeItem("videoText");
    sessionStorage.removeItem("paymentId");
    window.history.replaceState(null, "", window.location.origin);
  },[]);

  return (
    <Box width="100%" height="100%" position="relative"
      sx={{ overflowY:"auto", overflowX:"hidden", bgcolor:"#12141D"}}>
      <Box sx={{
        ...componentSx.bgStyle1,
        transform:"translateX(-50%)", position:"absolute", bgcolor: "#A930FE1A", borderRadius:"50%", zIndex: 1,
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle2,
        transform:"translateX(-50%)", position:"absolute", bgcolor: "#FC743A1A", borderRadius:"50%"
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle3,
        transform:"translateX(50%)", position:"absolute", bgcolor: "#F9BC521A", borderRadius:"50%"
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle4,
        position:"absolute", bgcolor: "#A930FE1A", borderRadius:"50%"
      }}></Box>
      <Box sx={{
        ...componentSx.bgStyle5,
        position:"absolute", bgcolor: "#3FA0FB1A", borderRadius:"50%"
      }}></Box>

      <Box width="100%" overflow="hidden">
        <PageStack alignItems="center">
          <PageMainHeader />
          <Typography fontWeight="700" textAlign="center"
            color="#E0E0E0"
            mt={ isSm ? "0.71rem" : "0.96rem" }
            fontSize={ isSm ? "0.4rem" : "0.6rem" }
            lineHeight={ isSm ? "0.5rem" : "0.76rem" } >
            Create Faceless<br />
            Short Videos
          </Typography>
          <Typography textAlign="center" fontWeight="700"
            fontSize={ isSm ? "0.4rem" : "0.6rem" }
            lineHeight={ isSm ? "0.56rem" : "0.76rem" }
            sx={{
              backgroundImage: "linear-gradient(90deg, #235DFF 0%, #BB2BFF 100%)", backgroundClip: "text",
              color: "transparent", position: "relative",
              "&::before": {
                content: "' '", position: "absolute", top: "86%", left: "0", width: "100%", height: "4%",
                backgroundImage: "linear-gradient(90deg, #235DFF 0%, #BB2BFF 100%)"
              }
            }}>
            in Seconds
          </Typography>
          <Typography p="0.3rem 0.24rem 0.42rem" color="#B7B7B7" maxWidth="5rem" textAlign="center"
            fontSize={isSm ? "0.16rem" : "0.24rem"}>
            StoryLoom offers the simplest experience to scale your video creation. 
          </Typography>
          <Box p="0rem 0.2rem 0.6rem" maxWidth="10rem" width="100%">
            <PageInput isSM={isSm} onChange={ jumpHrefUrl } />
          </Box>
        </PageStack>
      </Box>
      <Box width="100%">
        <PageBox p="1rem 0">
          <Typography textAlign="center" fontWeight={700}
            fontSize={ isSm ? "0.2rem" : "0.4rem" } lineHeight={ isSm ? "0.4rem" : "0.6rem" } >
            Endless Story Exploration
          </Typography>
          <Typography textAlign="center"
            fontSize={ isSm ? "0.16rem" : "0.32rem" } lineHeight={ isSm ? "0.24rem" : "0.48rem" }
            // fontSize={ isSm ? "0.14rem" : "0.2rem" } lineHeight={isSm ? "0.2rem" : "0.32rem"}
            color="#797979">
            Surprise yourself with every video!
          </Typography>
          <Box sx={{
            mask: 'linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%)',
            overflow: "hidden", width:"100%",
            "&:hover .geoduck-audio_one_cartoon": {
              animationPlayState: "paused"
            }
          }}>
            <PageSwiper />
          </Box>
        </PageBox>
      </Box>
      <Box width="100%">
        <PageBox p="0 0.24rem 0.5rem 0.24rem">
          <Typography textAlign="center" fontWeight={700}
            fontSize={ isSm ? "0.2rem" : "0.4rem" } lineHeight={ isSm ? "0.4rem" : "0.6rem" } >
            Faster. Simpler. Cheaper.
          </Typography>
          <Typography textAlign="center" color="#797979"
            fontSize={ isSm ? "0.16rem" : "0.32rem" } lineHeight={ isSm ? "0.24rem" : "0.48rem" }>
            Create videos in seconds with <br />
            slick B-rolls, captions, and BGM.
          </Typography>
          <Grid gap={ isMd ? "0.2rem" : "0.4rem" } display="grid" mt="0.64rem"
            gridTemplateColumns={ isMd ? "repeat(1, 1fr)" : "repeat(2, 1fr)" }>
            {
              discoverList.map((item, index)=>(
                <Stack key={index} direction="row" alignItems="center" gap="0.16rem" >
                  <Stack sx={componentSx.stackIcon} position="relative" flexShrink={0}
                    alignItems="center" justifyContent="center" >
                    <CircleDashedIcon sx={{ width:"100%", height:"100%",position:"absolute", left:"0", top:"0" }} />
                    { item.icon }
                  </Stack>
                  <Stack flexGrow="1" height="100%">
                    <Typography fontSize={ isSm ? "0.26rem" : "0.32rem" } fontWeight="700" 
                      lineHeight={ isSm ? "0.4rem" : "0.58rem" }>
                      {item.name}
                    </Typography>
                    <Typography fontSize={ isSm ? "0.16rem" : "0.24rem" } color="#797979" mt="0.1rem"
                      lineHeight={ isSm ? "0.24rem" : "0.36rem" }>
                      {item.desc}
                    </Typography>
                  </Stack>
                </Stack>
              ))
            }
          </Grid>
        </PageBox>
      </Box>
      <Box width="100%" >
        <PageStack sx={componentSx.isRow} p="0.3rem 0.24rem 0.6rem 0.24rem">
          <CardMedia src="/pages/page-picture.jpg" sx={componentSx.pictures} component="img" />
          <Stack justifyContent="center" alignItems="center" p={ isMd ? "0.2rem 0" : "0.2rem 0.6rem" }>
            <Typography fontWeight="700" fontSize={componentSx["fs4"]}>
              With your creative minds and the
              power of AI, a captivating story is just
              a step away.
            </Typography>
          </Stack>
        </PageStack>
      </Box>
      <Box width="100%" >
        <PageStack p="0.4rem 0.24rem 0.6rem 0.24rem">
          <Typography textAlign="center" fontWeight={700}
            fontSize={ isSm ? "0.2rem" : "0.4rem" } lineHeight={ isSm ? "0.4rem" : "0.6rem" } >
            Pricing
          </Typography>
          <Typography textAlign="center" color="#797979"
            fontSize={ isSm ? "0.16rem" : "0.32rem" } lineHeight={ isSm ? "0.24rem" : "0.48rem" }>
            Pay For What You Need
          </Typography>
          <Box height="0.64rem"></Box>
          <MealList onChange={paymantChange} />
        </PageStack>
      </Box>
      
      <Box width="100%" >
        <PageStack p="0.3rem 0.24rem" borderTop="1px solid #797979" direction={ isSm ? "column" : "row" }
          justifyContent="space-between" gap="0.1rem">
          <Typography textAlign="center" fontWeight="400" fontSize="0.16rem" color="#797979">
            StoryLoom © 2024.
          </Typography>
          <Stack direction="row" alignItems="center" gap="0.1rem" justifyContent="center">
            <CardMedia component="img" src="/logo.png" sx={{ width:"0.4rem" }} />
            <Typography fontSize="0.2rem" lineHeight="0.4rem" fontWeight="700">
              StoryLoom
            </Typography>
          </Stack>
          <Stack gap="0.1rem" fontSize="0.16rem" lineHeight="0.24rem">
            <Link sx={{ fontSize: "inherit",lineHeight:"inherit", justifyContent:"flex-start", p:"0 0.1rem" }}
              href="mailto:support@storyloom.ai" title="support@storyloom.ai">
              Contact
            </Link>
            <Link sx={{ fontSize: "inherit",lineHeight:"inherit", justifyContent:"flex-start", p:"0 0.1rem" }}
              href="/terms_of_use" >
              Terms of Use
            </Link>
            <Link sx={{ fontSize: "inherit",lineHeight:"inherit", justifyContent:"flex-start", p:"0 0.1rem" }}
              href="/privacy_policy" >
              Privacy Policy
            </Link>
          </Stack>
        </PageStack>
      </Box>
    </Box>
  );
}