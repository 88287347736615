import { CardMedia, Stack, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Messages } from "../../localization/Messages";
import { ButtonText } from "../muiCustom/Button";
import { useBreakpointSm } from "../../utils/useStyleHooks";

export default function PageMainHeader(){
  const navigate = useNavigate();
  const intl = useIntl();
  const isSm = useBreakpointSm();
  return (
    <Stack direction="row" sx={{
      width:"100%", p:"0.2rem 0.1rem",
      alignItems: "flex-start"
    }} >
      <Stack direction="row" alignItems="center" gap="0.1rem">
        <CardMedia component="img" src="/logo.png" sx={{ width:"0.4rem" }} />
        <Typography fontSize="0.25rem" lineHeight="0.4rem" fontWeight="800">
          StoryLoom
        </Typography>
      </Stack>
      <Box flexGrow="1"></Box>
      <Stack direction={isSm ? "column-reverse" : "row" } alignItems="flex-end" gap="0.1rem">
        <ButtonText sx={{ fontSize:"0.16rem", p:"0 0.2rem", height:"0.4rem" }}
          onClick={()=>{ navigate("/account/login") }}>
          {intl.formatMessage(Messages.signIn)}
        </ButtonText>
        <Button sx={{
          fontSize:"0.16rem", p:"0 0.2rem", height:"0.4rem", borderRadius:"0.3rem",
          backgroundImage: "linear-gradient(90deg, #235DFF 0%, #BB2BFF 100%)",
        }}
          onClick={()=>{ navigate("/account/login") }}>
          {intl.formatMessage(Messages.TryForFree)}
        </Button>
      </Stack>
    </Stack>
  )
}