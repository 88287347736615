import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import Color from 'color';

export const useInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    inlineMenuItem: { display: 'inline-block', margin: theme.spacing(0.5) },
  })
);

const arrowStyles:{
  position: "absolute";
  width: string;
  height: string;
  backgroundColor: string;
} = {
  position: "absolute",
  width: "10px",
  height: "10px",
  backgroundColor: "#000",
}

/**
 * global style will be overrided by customTheme
 * specify the !important to make it with first priority.
 */
export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderBottom: {
      borderBottom: `0.0625rem solid ${theme.palette.neutral[600]}`,
    }
  })
);
