import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../store';

export type AuthSliceType = {
  anyData?: {[key: string]: any};
  videoInfo: CreateVideoAPIResponse | null;
};

const initialState: AuthSliceType = {
  anyData: {},
  videoInfo: null
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setAnyData(state: AuthSliceType, action: PayloadAction<{ [key: string]: any }>) {
      let key = Object.keys(action.payload);
      let obj:{[key: string]: any} = {};
      key.forEach((k) => {
        obj[k] = action.payload[k];
      });
      state.anyData = obj;
    },
    setVideoInfo(state: AuthSliceType, action: PayloadAction<CreateVideoAPIResponse>) {
      state.videoInfo = action.payload;
    }
  },
});

export const {
  setAnyData,
  setVideoInfo
} = slice.actions;

export const selectProduct = createSelector(
  [(state: RootState) => state.product],
  (state) => state
);

export default slice.reducer;
